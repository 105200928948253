import React, { useState } from "react";
import "./BillTab.css";
import Chart from "chart.js/auto";
import download from "../../StepForm/image/download.png";
import exelsheet from "../../StepForm/image/excel.png";
import pdf from "../../StepForm/image/pdf.png";
import { Line } from "react-chartjs-2";
import { Doughnut } from "react-chartjs-2";
import { Bar } from "react-chartjs-2";
import GenerateTabFirst from "../generatetab-first/GenerateTabFirst";
import TwoDGraph from "../TwoGraph/TwoDGraph.js";
import AllTabs from "../../create-bill/all-tab/AllTabs";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Recent from "../../GeneratedBill/Recent/Recent/Recent.js";
import Form from "../../Mobile-View/Form/Form";
import MobRecent from "../Recent/MobRecent/MobRecent";
import StepForm from "../../StepForm/StepForm";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import { useEffect } from "react";
import circle from "../images/circle.png";
import Cookies from "js-cookie";
import Billlist from "../Bill_list/Billlist.js";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Row from "react-bootstrap/Row";
import Tab from "react-bootstrap/Tab";
library.add(fas);

const BillTab = () => {
  const { getData, postData } = useContext(Context);
  const [active, setActive] = useState("1"); // Set the initial active tab key

  // Function to handle tab change and update 'active' state
  const handleTabChange = async (key) => {
    await setActive(key);
  };
  const [lable1, setLable1] = useState([]);
  const [lable2, setLable2] = useState([]);
  const [lable3, setLable3] = useState([]);
  const [datacount1, setdatacount1] = useState([]);
  const [datacount2, setdatacount2] = useState([]);
  const [datacount3, setdatacount3] = useState([]);
  const [totalCount1, setTotalCount1] = useState();
  const [totalCount2, setTotalCount2] = useState();

  const getBarData = async () => {
    const respons1 = await getData("/dashboard/genereatebill/getgeneratebill");
    const respons2 = await getData("/dashboard/genereatebill/getwardcovered");

    if (respons1?.success) {
      const monthNames = respons1?.data?.recordsCountByMonth?.map(
        (element) => element?.monthName
      );
      setLable1(monthNames);
      const data = respons1?.data?.recordsCountByMonth?.map(
        (element) => element?.recordCount
      );
      setdatacount1(data);
      setTotalCount1(respons1?.data?.totalCount);
    }

    if (respons2?.success) {
      const monthNames2 = respons2?.data?.firstHalf?.map(
        (element) => element?.monthName
      );

      const monthNames3 = respons2?.data?.secondHalf?.map(
        (element) => element?.monthName
      );

      setLable2(monthNames2);
      setLable3(monthNames3);
      const data2 = respons2?.data?.firstHalf?.map(
        (element) => element?.recordCount
      );
      const data3 = respons2?.data?.secondHalf?.map(
        (element) => element?.recordCount
      );
      setdatacount2(data2);
      setdatacount3(data3);
      setTotalCount1(respons1?.data?.totalCount);
      setTotalCount2(respons2?.data?.totalCount);
    }
  };

  useEffect(() => {
    getBarData();
  }, []);

  const [formData, setFormData] = useState({
    file: null,
  });

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (formData.file) {
      // Perform file upload logic here
      const formDataUpload = new FormData();
      formDataUpload.append("file", formData.file);

      // Example of sending the form data to an API
      try {
        const response = await postData(
          "/createbill/createbillbulk",
          formDataUpload,
          {}
        );

        setShowModal(true);
        setSuccess(true);
        setTimeout(() => {
          setInputShow(!inputeShow);
          setShowModal(false);
        }, 1000);
      } catch (error) {
        // Handle errors
        console.error(error);
      }
    }
  };

  const [inputeShow, setInputShow] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [success, setSuccess] = useState(true);

  const handleFileChange = (e) => {
    setFormData({ ...formData, file: e.target.files[0] });
    setInputShow(!inputeShow);
  };

  // doughnut charts
  const data = {
    labels: lable2,
    datasets: [
      {
        label: "Data",
        data: datacount2,
        fill: false,
        borderColor: "rgba(254, 180, 117, 0.6)",
        backgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
        hoverBackgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
        outerWidth: ["30"],
        tension: 0.4,
      },
    ],
  };

  const data9 = {
    labels: lable3,
    datasets: [
      {
        label: "Data",
        data: datacount3,
        fill: false,
        borderColor: "rgba(254, 180, 117, 0.6)",
        backgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
        hoverBackgroundColor: ["rgba(254, 180, 117, 0.6", "#FE7600"],
        outerWidth: ["30"],
        tension: 0.4,
      },
    ],
  };

  // spline charts
  const data1 = {
    labels: lable1,
    datasets: [
      {
        label: "Months",
        data: datacount1,
        borderColor: "rgba(254, 180, 117, 0.6)",
        backgroundColor: ["#FE7600"],
      },
    ],
  };
  // bar chart
  const data3 = {
    labels: [
      "jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Example Dataset",
        data: [150, 100, 180, 80, 180, 200, 140, 200, 170, 80, 45, 90],
        backgroundColor: [
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
          "rgba(254, 180, 117, 0.6)",
          "#FE7600",
        ],
      },
    ],
  };

  const [isActive2, setIsActive2] = useState(true);
  const handleClick2 = async () => {
    setIsActive2(!isActive2);
  };
  const [isActive3, setIsActive3] = useState(false);
  const handleClick3 = async (id = null) => {
    setIsActive3(!isActive3);
    if (id) {
      await Cookies.set("createbillid", id, { expires: 2 });
      // handleTabChange("8");
    }
  };

  const ExcelhandleDownload = () => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/exceldownloadgeneratebill"; // Replace with the actual path
  };

  const PdfhandleDownload = () => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/pdfdownloadgeneratebill"; // Replace with the actual path
  };
  return (
    <>
      <section className="BillTab ">
        <div className="container-fluid p-0">
          <div
            className={
              isActive2 ? "main-tab-class add-color" : "main-tab-class"
            }
            // className={"main-tab-class "}
          >
            <div className="container py-5 pe-0">
              {/* <div className="row">
                <div className="col-md-12 mt-5">
                  <div className="head-tab">
                    <ul
                      className="nav nav-pills mt-5 "
                      id="pills-tab"
                      role="tablist"
                    >
                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className="nav-item me-md-3 me-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link active "
                            id="pills-Generated-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Generated "
                            type="button"
                            role="tab"
                            aria-controls="pills-Generated"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon
                                className=""
                                icon="fa-solid fa-file"
                              />
                              <div className="card-body text-center">
                                <p className="total">Total Generated Bill</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 mt-3 ">
                        <li
                          className="nav-item me-md-3 me-0 mt-3 mt-md-0 "
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="pills-Notice-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Notice"
                            type="button"
                            role="tab"
                            aria-controls="pills-Notice"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-book" />
                              <div className="card-body">
                                <p className="total">
                                  Total Notice <br /> Generated
                                </p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className="nav-item me-md-3 me-0 mt-3 mt-md-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link"
                            id="pills-Tax-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Tax"
                            type="button"
                            role="tab"
                            aria-controls="pills-Tax"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                              <div className="card-body text-center">
                                <p className="total">Total Tax Collection</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className="nav-item  me-xl-3 me-lg-0 me-md-0 me-0 mt-3 mt-md-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link"
                            id="pills-Ward-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Ward"
                            type="button"
                            role="tab"
                            aria-controls="pills-Ward"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
                              <div className="card-body text-center">
                                <p className="total">
                                  Total ward <br /> Covered
                                </p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className="nav-item me-md-3 me-0 mt-3 mt-md-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link"
                            id="pills-Arrears-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Arrears"
                            type="button"
                            role="tab"
                            aria-controls="pills-Arrears"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-hand-holding-hand" />
                              <div className="card-body text-center">
                                <p className="total">
                                  Total Outstanding <br /> amount
                                </p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3 ">
                        <li
                          className="nav-item me-md-3 me-0 mt-3 mt-md-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link "
                            id="pills-Recent-tab  "
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Recent"
                            type="button"
                            role="tab"
                            aria-controls="pills-Recent"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                              <div className="card-body text-center">
                                <p className="total">Recent Activity</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-2 col-xl-2 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className="nav-item me-md-3 me-0 mt-3 mt-md-0"
                          role="presentation"
                        >
                          <button
                            className="nav-link  "
                            id="pills-Invoices-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Invoices"
                            type="button"
                            role="tab"
                            aria-controls="pills-Invoices"
                            aria-selected="false"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" />
                              <div className="card-body text-center">
                                <p className="total">
                                  Total Invoices Generated Count Per Month/Day
                                </p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>

                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className=" nav-item-create me-2  mt-3 mt-md-0 "
                          role="presentation"
                        >
                          <button
                            onClick={handleClick2}
                            className={`nav-link`}
                            id="pills-Create-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-Create"
                            type="button"
                            role="tab"
                            aria-controls="pills-Create"
                            aria-selected="true"
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                              <div className="card-body text-center">
                                <p className="total">Create Bill</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>



                      <div className="col-xxl-1 col-xl-1 col-lg-3 col-md-3 col-sm-12 col-12 mt-3">
                        <li
                          className=" nav-item-create me-2  mt-3 mt-md-0 "
                          role="presentation"
                        >
                          <button
                            onClick={() => {
                              handleClick3();
                            }}
                            className={`nav-link `}
                            id="pills-billlist-tab"
                            data-bs-toggle="pill"
                            data-bs-target="#pills-billlist"
                            type="button"
                            role="tab"
                            aria-controls="pills-billlist"
                            // aria-selected="false"
                            aria-selected={isActive3 ? "true" : "false"}
                          >
                            <div className="card-img-top">
                              <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                              <div className="card-body text-center">
                                <p className="total">Bill List</p>
                              </div>
                            </div>
                          </button>
                        </li>
                      </div>
                    </ul>
                  </div>
                </div>

                <div className="tab-content" id="pills-tabContent">

                  <div
                    className="tab-pane fade show active"
                    id="pills-Generated"
                    role="tabpanel"
                    aria-labelledby="pills-Generated-tab"
                    tabindex="0"
                  >
                    <div className=" ">
                      <GenerateTabFirst />
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Notice"
                    role="tabpanel"
                    aria-labelledby="pills-Notice-tab"
                    tabindex="0"
                  >
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4 ">
                        <div className="main-total mt-5">
                          <p className="text-center p-3 ">
                            Notice Generated - {totalCount1}
                          </p>
                        </div>
                        <div className="excel-pdf-tab ">
                          <div
                            className="main-total2"
                            onClick={ExcelhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div
                            className="main-total2"
                            onClick={PdfhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div>
                            <p className="text-end text-graph">
                              Generated Bill
                            </p>
                            <Line data={data1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Tax"
                    role="tabpanel"
                    aria-labelledby="pills-Tax-tab"
                    tabindex="0"
                  >
                    <TwoDGraph />
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Ward"
                    role="tabpanel"
                    aria-labelledby="pills-Ward-tab"
                    tabindex="0"
                  >
                    <div className="row ">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 ps-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Ward Covered - {totalCount2}
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div
                            className="main-total2"
                            onClick={ExcelhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-3"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div
                            className="main-total2"
                            onClick={PdfhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <div className=" row shadow-sm p-3 mb-5 bg-body mt-5">
                          <p className="text-end text-graph-title">
                            Total Ward Covered in months
                          </p>
                          <div className="col-md-6 ">
                            <Doughnut data={data} />
                          </div>
                          <div className="col-md-6">
                            <Doughnut data={data9} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Arrears"
                    role="tabpanel"
                    aria-labelledby="pills-Arrears-tab"
                    tabindex="0"
                  >
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Outstanding Amount
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div>
                            <Line data={data1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Recent"
                    role="tabpanel"
                    aria-labelledby="pills-Recent-tab"
                    tabindex="0"
                  >
                    <div className="col-md-12">
                      <div className="desktop-view">
                        <Recent />
                      </div>
                      <div className="mobile-view">
                        <MobRecent />
                      </div>
                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-Invoices"
                    role="tabpanel"
                    aria-labelledby="pills-Invoices-tab"
                    tabindex="0"
                  >
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Invoices Generated Count Per Month/Day
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div className="main-total1 text-end">
                            <div>
                              <span className="orange-box">...</span> 2023
                            </div>
                            <div>
                              <span className="light-orange">...</span> 2022
                            </div>
                          </div>
                          <div>
                            <Bar data={data3} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-Create"
                    role="tabpanel"
                    aria-labelledby="pills-Create-tab"
                    tabindex="0"
                  >
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-5 p-0">
                      <div className="alltabs-view">
                        <StepForm isActive2={isActive2} isActive3={isActive3} />

                      </div>

                    </div>
                  </div>


                  <div
                    className="tab-pane fade"
                    id="pills-billlist"
                    role="tabpanel"
                    aria-labelledby="pills-billlist-tab"
                    tabindex="0"
                  >
                    <div className="bill-list-conttt-div">
                      <Billlist handleClick={handleClick3} />
                    </div>
                  </div>
                </div>
              </div> */}

              <Tab.Container
                id="left-tabs-example"
                // defaultActiveKey="1"
                activeKey={active}
              >
                <Nav variant="pills" className="billingTabs_main">
                  <Nav.Item>
                    <Nav.Link eventKey="1" onClick={() => handleTabChange("1")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon className="" icon="fa-solid fa-file" />
                        <div className="card-body text-center">
                          <p className="total">
                            Total <br /> Generated <br />
                            Bill
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="notice_txt">
                    <Nav.Link eventKey="2" onClick={() => handleTabChange("2")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-book" />
                        <div className="card-body">
                          <p className="total">
                            Total Notice <br />
                            Generated
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="3" onClick={() => handleTabChange("3")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                        <div className="card-body text-center">
                          <p className="total">
                            Total Tax <br />
                            Collection
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="4" onClick={() => handleTabChange("4")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-map-location-dot" />
                        <div className="card-body text-center">
                          <p className="total">
                            Total <br />
                            ward
                            <br /> Covered
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item className="outstanding_txt">
                    <Nav.Link eventKey="5" onClick={() => handleTabChange("5")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-hand-holding-hand" />
                        <div className="card-body text-center">
                          <p className="total">
                            Total Outstanding <br /> amount
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="6" onClick={() => handleTabChange("6")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-file-invoice" />
                        <div className="card-body text-center">
                          <p className="total">
                            Recent
                            <br /> Activity
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="7" onClick={() => handleTabChange("7")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-file-invoice-dollar" />
                        <div className="card-body text-center">
                          <p className="total">
                            Total Invoices Generated <br /> Count Per Month/Day
                          </p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="8" onClick={() => handleTabChange("8")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        <div className="card-body text-center">
                          <p className="total">Create Bill</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="9" onClick={() => handleTabChange("9")}>
                      <div className="card-img-top">
                        <FontAwesomeIcon icon="fa-solid fa-pen-to-square" />
                        <div className="card-body text-center">
                          <p className="total">Bill List</p>
                        </div>
                      </div>
                    </Nav.Link>
                  </Nav.Item>
                </Nav>

                <Tab.Content>
                  <Tab.Pane eventKey="1">
                    <GenerateTabFirst />
                  </Tab.Pane>
                  <Tab.Pane eventKey="2">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4 ">
                        <div className="main-total mt-5">
                          <p className="text-center p-3 ">
                            Notice Generated - {totalCount1}
                          </p>
                        </div>
                        <div className="excel-pdf-tab ">
                          <div
                            className="main-total2"
                            onClick={ExcelhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div
                            className="main-total2"
                            onClick={PdfhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div>
                            <p className="text-end text-graph">
                              Generated Bill
                            </p>
                            <Line data={data1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="3">
                    <TwoDGraph />
                  </Tab.Pane>
                  <Tab.Pane eventKey="4">
                    <div className="row ">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 ps-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Ward Covered - {totalCount2}
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div
                            className="main-total2"
                            onClick={ExcelhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-3"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div
                            className="main-total2"
                            onClick={PdfhandleDownload}
                          >
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 col-12">
                        <div className=" row shadow-sm p-3 mb-5 bg-body mt-5">
                          <p className="text-end text-graph-title">
                            Total Ward Covered in months
                          </p>
                          <div className="col-md-6 ">
                            <Doughnut data={data} />
                          </div>
                          <div className="col-md-6">
                            <Doughnut data={data9} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="5">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Outstanding Amount
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div>
                            <Line data={data1} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="6">
                    <div className="col-md-12">
                      <div className="desktop-view">
                        <Recent />
                      </div>
                      <div className="mobile-view">
                        <MobRecent />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="7">
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-4">
                        <div className="main-total mt-5">
                          <p className="text-center p-3">
                            Total Invoices Generated Count Per Month/Day
                          </p>
                        </div>
                        <div className="excel-pdf-tab">
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={exelsheet}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              Excelsheet{" "}
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                          <div className="main-total2">
                            <p className="text-center p-3">
                              <img
                                src={pdf}
                                className="excelimg me-5 me-md-3"
                                alt="..."
                              />
                              PDF File
                              <img
                                src={download}
                                className="excelimg ms-5 ms-md-2"
                                alt="..."
                              />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5">
                        <div className="graph p-3 mb-5">
                          <div className="main-total1 text-end">
                            <div>
                              <span className="orange-box">...</span> 2023
                            </div>
                            <div>
                              <span className="light-orange">...</span> 2022
                            </div>
                          </div>
                          <div>
                            <Bar data={data3} />
                          </div>
                        </div>
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="8">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12 mt-5 p-0">
                      <div className="alltabs-view">
                        <StepForm isActive3={isActive3} />
                      </div>
                    </div>
                  </Tab.Pane>
                  <Tab.Pane eventKey="9">
                    <div className="bill-list-conttt-div">
                      <Billlist
                        handleTabChange={handleTabChange}
                        handleClick={handleClick3}
                      />
                    </div>
                  </Tab.Pane>
                </Tab.Content>
              </Tab.Container>
            </div>
          </div>
        </div>

        <div className="save-modal">
          <div
            className={`modal fade ${showModal ? "show" : ""}`}
            style={{ display: showModal ? "block" : "none" }}
            id="exampleModal1"
            tabindex="-1"
            aria-labelledby="exampleModalLabel"
            aria-hidden="true"
          >
            <div className="modal-dialog  modal-dialog-centered">
              <div className="modal-content created-bill-modal">
                <div className="modal-body">
                  <div className="circle justify-content-end">
                    <img src={circle} className="circle-img mb-2" alt="" />
                  </div>
                  <h1 className="add-success text-center">
                    Bulk Uploaded Successfully
                  </h1>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};
export default BillTab;
