import React from "react";
import "../../Master/Tabels/Tabels.css";
import { Link } from "react-router-dom";
import plus from "../../assets/icons/PLUS.png";
import colunms from "../../assets/icons/LINES.png";
import pen from "../../assets/icons/pen.png";
import basket from "../../assets/icons/basket.png";
import search from "../../assets/icons/search.png";
import top from "../../assets/icons/top.png";
import blackeye from "../../assets/icons/blackeye.png";
import whiteimg from "../../assets/icons/eye.png";
const Table = () => {
  return (
    <>
      <div className="col-xxl-12 col-xl-12   ">
        <section className="Tabels tab-radio mt-4">
          <div className="container">
            {/* <div className="row   mt-3">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb">
                                        <li className="breadcrumb-item">
                                            Tax Master <img src={arrow} className="image" alt="" />
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            Country
                                        </li>
                                    </ol>
                                </nav>
                            </div> */}
            <div className="row">
              <div className="d-flex">
                <div className="add me-3">
                  <Link to="">
                    <button type="button" className="btn btn-add pe-3">
                      {" "}
                      <img src={plus} className="plus me-2 ms-0" alt="" /> Add
                      Tax{" "}
                    </button>
                  </Link>
                </div>

                <div className="add">
                  <div className="dropdown">
                    <button
                      className="btn btn-columns dropdown-toggle"
                      type="button"
                      data-bs-toggle="dropdown"
                      aria-expanded="false"
                    >
                      {" "}
                      <img src={colunms} className="columns " alt="" /> Column
                      Selection <img src={top} className="top ms-1" alt="" />{" "}
                    </button>
                    <ul className="dropdown-menu">
                      <li>
                        <Link className="dropdown-item" href="#">
                          Sr. No.
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Tax Name
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Tax Rate
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Sub Tax Rate
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Include in Rate
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Status
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                      <li>
                        <Link className="dropdown-item" href="#">
                          Active
                          <img src={blackeye} className="eye1 " alt="" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="view-details ">
                  <Link to="">
                    {" "}
                    <button className="btn btn-view">
                      <img src={whiteimg} className="eye-img me-2" alt="" />
                      View Details
                    </button>
                  </Link>
                </div>
              </div>
            </div>
            <div className="border-line mt-3"></div>
            <div className="row mt-3">
              <div className="data table-responsive">
                <table className="table table-bordered  tableborder">
                  <thead>
                    <tr className="">
                      <th className="check round-check">
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckChecked"
                            checked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckChecked"
                          ></label>
                          {/* <img src={check} className="check round-check" alt="" /> */}
                        </div>
                      </th>
                      <th className="sr">Sr. No.</th>
                      <th className="tax-name">Owner Name</th>
                      <th className="tax">Tax Name</th>
                      <th className="rate">Property Type</th>
                      <th className="status">Due Date</th>
                      <th className="active">E-mail ID</th>
                      <th className="active">Address</th>
                      <th className="active">Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr className="">
                      <td>
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            value=""
                            id="flexCheckDefault"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexCheckDefault"
                          ></label>
                        </div>
                      </td>
                      <td>1.</td>
                      <td>1.</td>
                      <td>1.</td>
                      <td>Tax Name</td>
                      <td className="tax-rate">10.00</td>
                      <td className="rate">Yes</td>
                      <td>
                        <div className="d-flex">
                          <div className="circle mt-2 me-2"></div>
                          <div className="active">Active</div>
                        </div>
                      </td>

                      <td>
                        <div className="d-flex">
                          <div className="me-2">
                            <button type="button" className="btn btn-primary">
                              <img src={pen} className="pen" alt="" />
                            </button>
                          </div>
                          <div>
                            <button type="button" className="btn btn-danger">
                              <img src={basket} className="pen" alt="" />
                            </button>
                          </div>
                        </div>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
            <div className="row mt-4">
              <div className="  col-lg-4 col-md-4 col-12">
                <div className="d-flex">
                  <div className="show me-2">
                    <p className="show mt-2">Show</p>
                  </div>
                  <div className="number me-2">
                    <select
                      className="form-select form-select-sm"
                      aria-label=".form-select-sm example"
                    >
                      <option selected>10</option>
                      <option value="1">1</option>
                      <option value="2">2</option>
                      <option value="3">3</option>
                    </select>
                  </div>
                  <div className="entries">
                    <p className="show mt-2">entries</p>
                  </div>
                </div>
              </div>
              <div className="  col-lg-8  col-md-8 col-12">
                <div className="d-flex justify-content-end">
                  <div className="sowing me-2">
                    <p className="show mt-2">Showing 1 to 4 of 10 entries</p>
                  </div>
                  <div className="num me-2">
                    <input type="number" className="form-control" id="" />
                  </div>
                  <div className="Search">
                    <button type="button" className="btn btn-search">
                      <img src={search} className="search" alt="" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Table;
