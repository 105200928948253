import React from "react";
import "../Form.css";
import saveimg from "../../../StepForm/image/awesome-save.png";
import preimg from "../../../StepForm/image/Icon awesome-backward.png";

const RoomDetails = () => {
  return (
    <>
      <section className="Property-Form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Pro No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Zone
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Autho. Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>A</option>
                <option>B</option>
                <option>C</option>
                <option>D</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Authorized?
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Legal
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Enter Room Details */}
          <div>
            <div className="mt-3">
              <h2>Enter Room Details</h2>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Flat No. / ID No.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Room Type
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Room Width
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Room Length
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Usage Type
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Sub Type
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Construction Class
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                <label htmlFor="inputPassword5" className="form-label">
                  Occupancy Status
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>A</option>
                  <option>B</option>
                  <option>C</option>
                  <option>D</option>
                </select>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Manual Area
                </label>
                <div className="col-3">
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Exempted
                </label>
                <div className="col-3">
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1"></div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Built up Area
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Carpet Area
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Exempt Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Assessable Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Annual rate
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1"></div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4">
                <label htmlFor="inputPassword5" className="form-label">
                  Manual Rv
                </label>
                <div className="col-3">
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  ALV
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  RV
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-3">
                <button type="button" className="form-button-class">
                  Room Details
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-3">
                <button type="button" className="form-button-class">
                  OK
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-3">
                <button type="button" className="form-button-class">
                  Cancel
                </button>
              </div>
            </div>
            <div className="text-center ">
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded me-2"
              >
                {" "}
                <img src={preimg} className="saveimg" /> Back
              </button>
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded"
              >
                {" "}
                <img src={saveimg} className="saveimg " /> Save
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default RoomDetails;
