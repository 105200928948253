import React, { useContext } from "react";
import { Context } from "./utils/context.js";
import { useNavigate } from "react-router-dom";
import Login from "./Components/DataEntry/Login/Login.js";

const Middleware = ({ component: Component }) => {
  // Destructure `component` prop
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  if (signin) {
    if (usertype === "Admin") {
      return <Component />;
    } else {
      return <Component />;
    }
  } else {
    return <Login />;
  }
};

export default Middleware;
