import React from "react";
import BillTab from "../GeneratedBill/BillTab/BillTab";
import Popup from "../GeneratedBill/Popup/Popup";

const GeneratedBill = () => {
  return (
    <div>
      <BillTab />
      <Popup />
    </div>
  );
};

export default GeneratedBill;
