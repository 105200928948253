import React from "react";

import { Link } from "react-router-dom";
import "./Tax.css";
import sa from "../../assets/icons/sa.png";
import pencil from "../../assets/icons/pencil.png";
import Sidebar from "../layout/Sidebar";
const View = () => {
  return (
    <>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-11 mx-auto">
              <Sidebar />
              <div className="  col-xl-10 col-lg-8  col-md-12">
                <div className="row mt-5 ">
                  <div className=" col-lg-11 mx-auto View">
                    <form>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Owner Name{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          {" "}
                          Area of Property (Sq.Ft.)
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          {" "}
                          Land / Building{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          {" "}
                          Property Detailed Address{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Nearest Landmark
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="text"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          {" "}
                          Pin Code{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          {" "}
                          Email ID{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="email"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row mb-3 justify-content-center">
                        <label
                          htmlFor="colFormLabelSm"
                          className="col-sm-3 col-form-label col-form-label-sm"
                        >
                          Mobile Number{" "}
                        </label>
                        <div className="col-sm-6">
                          <input
                            type="number"
                            className="form-control form-control-sm"
                            id="colFormLabelSm"
                            placeholder=""
                          />
                        </div>
                      </div>
                      <div className="row ">
                        <div className="d-flex justify-content-center">
                          <div className="sa me-2">
                            <Link to="/customer-list">
                              {" "}
                              <button type="button" className="btn btn-sa">
                                {" "}
                                <img src={sa} className="sa me-1" alt="" />
                                Save
                              </button>
                            </Link>
                          </div>
                          <div className="pencil">
                            <button type="button" className="btn btn-sa">
                              {" "}
                              <img src={pencil} className="pencil me-1" alt="" />
                              Edit
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default View;
