import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "../BillHistory/History.css";
import view from "../../create-bill/image/view.png";
import restore from "../../create-bill/image/restore.png";
import { Context } from "../../../../utils/context";

const History = () => {
  return (
    <>
      <div className="card-history">
        <div className="main p-3">
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
              <p className="kumar">Guddu Kumar </p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-3">
              <p className="accept">Accepted</p>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to={"/history"}>
                <img src={view} className="view-img me-3" />
              </Link>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to={"/billview"}>
                <img src={restore} className="restore-img" type="button" />
              </Link>
            </div>
            <hr></hr>
          </div>
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
              <p className="kumar">Disha Marathe</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-3">
              <p className="reject">Rejected</p>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to={"/history"}>
                <img src={view} className="view-img me-3" />
              </Link>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to="/"></Link>{" "}
              <img
                src={restore}
                className="restore-img"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#HISTORYModal"
              />
            </div>
            <hr></hr>
          </div>
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
              <p className="kumar">Paresh Pawar</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-3">
              <p className="accept">Accepted</p>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to={"/history"}>
                <img src={view} className="view-img me-3" />
              </Link>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <img
                src={restore}
                className="restore-img"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#HISTORYModal"
              />
            </div>
            <hr></hr>
          </div>
          <div className="row">
            <div className="col-xxl-5 col-xl-5 col-lg-5 col-md-5 col-sm-5 col-5">
              <p className="kumar">Shubham Yadav</p>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-5 col-3">
              <p className="accept">Rejected</p>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <Link to={"/history"}>
                <img src={view} className="view-img me-3" />
              </Link>
            </div>
            <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2">
              <img
                src={restore}
                className="restore-img"
                type="button"
                data-bs-toggle="modal"
                data-bs-target="#HISTORYModal"
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default History;
