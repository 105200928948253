import React from "react";
import { Link } from "react-router-dom";
import sa from "../../assets/icons/sa.png";
import pencil from "../../assets/icons/pencil.png";
import backarrow from "../../assets/icons/back.png";
import Sidebar from "../../Master/layout/Sidebar";

const Edit = () => {
  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="back-btn-div">
                        <Link to="/tax-master">
                          <button className=" back-btn">
                            <img src={backarrow} className="sa" alt="" /> Back
                          </button>
                        </Link>
                      </div>
                      <hr />
                      <div className="View">
                        <form className="form-background">
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Owner Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Area of Property (Sq.Ft.)
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Land / Building
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Property Detailed Address
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Nearest Landmark
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Pin Code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Email ID
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="email"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Mobile Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-md-6 text-end">
                              <div className="pencil">
                                <button type="button" className=" cancel-btn">
                                  {" "}
                                  <img
                                    src={pencil}
                                    className="pencil"
                                    alt=""
                                  />{" "}
                                  Edit
                                </button>
                              </div>
                            </div>
                            <div className="col-md-6 justify-contend-end">
                              <div className="sa">
                                <button type="button" className=" save-btn">
                                  {" "}
                                  <img src={sa} className="sa" alt="" /> Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    </>
  );
};

export default Edit;
