import { createContext, useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import toast, { Toaster } from "react-hot-toast";

import {
  getData,
  postData,
  getEditData,
  editData,
  editStatusData,
  deleteData,
  getDownloadDataExcel,
  getDownloadDataPDF,
} from "./api";

export const Context = createContext();

const AppContext = ({ children }) => {
  // const IMG_URL = "http://127.0.0.1:6001";
  const IMG_URL = "http://billingsoftware.node.profcymabackend.com:6001";

  const navigate = useNavigate();

  const [signin, setSignin] = useState(false);
  const [language, setLanguage] = useState(1);
  const [usertype, setUsertype] = useState("");
  const [userdata, setUserData] = useState({});
  const [token, setToken] = useState("");

  const minLength = 1; // Set your desired minimum length
  const maxLength = 30; // Set your desired maximum length

  useEffect(() => {
    isTokenValid();
  }, [signin]);

  const getuserData = async (id) => {
    const response = await getData(`/login/getdata`, { id: id });
    await setUserData(response?.data);
  };

  const isTokenValid = async () => {
    const token = Cookies.get("billing_security");

    if (token) {
      // Decode the token to get the expiration time
      const decodedToken = JSON.parse(atob(token.split(".")[1]));
      const currentTime = Date.now() / 1000;

      // Check if the token has expired
      if (decodedToken.exp < currentTime) {
        Cookies.remove("billing_security", { path: "/" });
        setSignin(false);
        setUsertype("");
        navigate("/");
      } else {
        getuserData(decodedToken.user);
        setUsertype(decodedToken.role);
        setSignin(true);
      }
    } else {
      setSignin(false);
      setUsertype("");
      navigate("/");
    }
  };

  const ErrorNotify = (name) => {
    toast.error(`${name} deleted successfully.`, {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  return (
    <Context.Provider
      value={{
        minLength,
        maxLength,
        getData,
        postData,
        getEditData,
        editData,
        editStatusData,
        deleteData,
        signin,
        setSignin,
        usertype,
        setUsertype,
        userdata,
        setUserData,
        ErrorNotify,
        token,
        setToken,
        language,
        getDownloadDataExcel,
        getDownloadDataPDF,
        IMG_URL,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default AppContext;
