import React, { useState } from "react";
import { NavLink, useLocation, useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import "../HeaderDashBoard/HeaderDashBoard.css";
import setting from "../images/setting.png";
import layer from "../images/layer.png";
import { Nav } from "react-bootstrap";
import { useEffect } from "react";
import arrowleft from "../../assets/icons/arrow-left.png";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";

const HeaderDashBoard = (props) => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  const { setUserData, setUsertype, setSignin, usertype } = useContext(Context);

  const navigate = useNavigate();

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  const LogOut = async () => {
    Cookies.remove("billing_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  return (
    <>
      <section className="Header-main">
        <div className="container-fluid p-0">
          <div className="main-header">
            <div className="white-header"></div>
            <div className="Green-header">
              <div className="container-fluid set1">
                <div className="main-navbar">
                  {/* header nav bar started */}
                  <nav className="navbar navbar-expand-lg">
                    <div className="container-fluid  ">
                      <Link className="navbar-brand" href="#"></Link>
                      <button
                        className="navbar-toggler"
                        type="button"
                        data-bs-toggle="collapse"
                        data-bs-target="#navbarNavDropdown"
                        aria-controls="navbarNavDropdown"
                        aria-expanded="false"
                        aria-label="Toggle navigation"
                      >
                        <span className="navbar-toggler-icon"></span>
                      </button>
                      <div
                        className="collapse navbar-collapse"
                        id="navbarNavDropdown"
                      >
                        <ul className="navbar-nav justify-content-center d-flex">
                          {usertype && usertype === "Super Admin" ? (
                            <NavLink
                              className={
                                headerText.includes("/tax-master")
                                  ? "butt-decoration active"
                                  : "butt-decoration"
                              }
                              to="/tax-master/languages"
                            >
                              <div className="a">
                                <li className="nav-item">
                                  <a
                                    className="nav-link"
                                    aria-current="page"
                                    href="#"
                                  >
                                    Master - Tax Management
                                  </a>
                                </li>
                              </div>
                            </NavLink>
                          ) : (
                            <></>
                          )}

                          <NavLink className="butt-decoration" to="/data-entry">
                            <li className="nav-item">
                              <a
                                className="nav-link menu"
                                aria-current="page"
                                href="#"
                              >
                                Reports
                              </a>
                            </li>
                          </NavLink>

                          {usertype && usertype === "Super Admin" ? (
                            <NavLink
                              className="butt-decoration"
                              to="/counterwise"
                            >
                              {" "}
                              <li className="nav-item">
                                <a
                                  className="nav-link menu"
                                  aria-current="page"
                                  href="#"
                                >
                                  Collection Reports
                                </a>
                              </li>
                            </NavLink>
                          ) : (
                            <></>
                          )}
                          {usertype && usertype === "Super Admin" ? (
                            <NavLink
                              className="butt-decoration"
                              to="/rolemanagement"
                            >
                              <li className="nav-item">
                                <a
                                  className="nav-link menu"
                                  aria-current="page"
                                  href="#"
                                >
                                  Role Management
                                </a>
                              </li>
                            </NavLink>
                          ) : (
                            <></>
                          )}
                          <NavLink className="butt-decoration" to="/setting">
                            {" "}
                            <li className="nav-item dropdown">
                              <a
                                className="nav-link dropdown-toggle"
                                href="#"
                                role="button"
                                data-bs-toggle="dropdown"
                                aria-expanded="false"
                              >
                                Settings
                              </a>
                              <ul className="dropdown-menu">
                                {usertype && usertype === "Super Admin" ? (
                                  <>
                                    <Link
                                      className="dropdown-item"
                                      to="/company"
                                    >
                                      {" "}
                                      <li>
                                        <img
                                          src={setting}
                                          className="nutri me-1"
                                          alt=""
                                        />{" "}
                                        Basic Settings
                                      </li>
                                    </Link>
                                    <div className="border-horizental"></div>
                                    <li>
                                      <Link className="dropdown-item" href="#">
                                        <img
                                          src={layer}
                                          className="nutri me-1"
                                          alt=""
                                        />
                                        Advanced Settings
                                      </Link>
                                    </li>
                                  </>
                                ) : (
                                  <></>
                                )}

                                <li>
                                  <button
                                    onClick={LogOut}
                                    className="dropdown-item"
                                  >
                                    <img
                                      src={layer}
                                      className="nutri me-1"
                                      alt=""
                                    />
                                    Log Out
                                  </button>
                                </li>
                              </ul>
                            </li>
                          </NavLink>
                        </ul>
                      </div>
                    </div>
                  </nav>
                  {/* header nav bar end */}
                  <header>
                    <h1>
                      {usertype && usertype === "Super Admin" ? (
                        headerText === "/dashboard" ? (
                          <p className="text-holder">Super Admin Dashboard</p>
                        ) : (
                          <Link to="/dashboard">
                            <p className="text-holder">
                              <img src={arrowleft} className="arrow-img me-2" />
                              Back to Dashboard
                            </p>{" "}
                          </Link>
                        )
                      ) : headerText === "/data-entry" ? (
                        <p className="text-holder">Data Entry Reports</p>
                      ) : (
                        <Link to="/data-entry">
                          <p className="text-holder">
                            <img src={arrowleft} className="arrow-img me-2" />
                            Back to Data Entry Reports
                          </p>{" "}
                        </Link>
                      )}
                    </h1>
                    {/* Other header content */}
                  </header>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HeaderDashBoard;
