import React from "react";
import HeaderDashBoard from "./HeaderDashBoard/HeaderDashBoard.js";
import Heading from "./Heading/Heading.js";
import { useContext } from "react";
import { Context } from "../../../utils/context.js";
import { useNavigate } from "react-router-dom";

const Header = (props) => {
  const title = props.title;
  const { signin } = useContext(Context);
  const navigate = useNavigate();
  return <div>{signin ? <HeaderDashBoard /> : <></>}</div>;
};

export default Header;
