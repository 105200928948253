import React from "react";
import "../Form.css";
import saveimg from "../../../StepForm/image/awesome-save.png";
import preimg from "../../../StepForm/image/Icon awesome-backward.png";

const FlatDetails = () => {
  return (
    <>
      <section className="Property-Form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 mt-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 mt-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 mt-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Pro No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Zone
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Autho. Status
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property Status
              </label>
              <div className="dropdown">
                <button
                  className="btn dropdown-btn-class dropdown-toggle"
                  type="button"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  --Select--
                </button>
                <ul className="dropdown-menu">
                  <li>
                    <a className="dropdown-item" href="#"></a>1
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>2
                  </li>
                  <li>
                    <a className="dropdown-item" href="#"></a>3
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Authorized?
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Legal
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Flat Details */}
          <div>
            <div className="mt-3">
              <h2>Flat Details</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Flat No. / ID No.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Location
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    --Select--
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Occupancy Status
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    --Select--
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Current Ass. date
                </label>
                <input
                  type="date"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Location
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    --Select--
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Occupancy Status
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    --Select--
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Current Ass. date
                </label>
                <input
                  type="date"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Usage Type
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    --Select--
                  </button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Sub Type
                </label>
                <div className="dropdown">
                  <button
                    className="btn dropdown-btn-class dropdown-toggle"
                    type="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  ></button>
                  <ul className="dropdown-menu">
                    <li>
                      <a className="dropdown-item" href="#"></a>1
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>2
                    </li>
                    <li>
                      <a className="dropdown-item" href="#"></a>3
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Construction Class
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1"></div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1"></div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Owner Name
                </label>
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 me-1">
                    <div className="dropdown">
                      <button
                        className="btn dropdown-btn-class dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#"></a>1
                        </li>
                        <li>
                          <a className="dropdown-item" href="#"></a>2
                        </li>
                        <li>
                          <a className="dropdown-item" href="#"></a>3
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-9 col-9">
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                    <div id="passwordHelpBlock" className="form-text">
                      <p className="text-center">Last Name</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-2 ">
                <label htmlFor="inputPassword5" className="form-label"></label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
                <div id="passwordHelpBlock" className="form-text">
                  <p className="text-center">First Name</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-2">
                <label htmlFor="inputPassword5" className="form-label"></label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
                <div id="passwordHelpBlock" className="form-text">
                  <p className="text-center">Middle Name</p>
                </div>
              </div>
              {/* Button */}
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 ">
                <button type="button" className="form-button-class">
                  Additional Owner
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <button type="button" className="form-button-class">
                  Bill Address
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <button type="button" className="form-button-class">
                  Address
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Standard Rate
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Built up Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Carpet Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Annual Rent
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Construction Cost
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Exempt. Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <label htmlFor="inputPassword5" className="form-label">
                  Manual RV
                </label>
                <div className="col-3">
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <label htmlFor="inputPassword5" className="form-label">
                  Auto RV
                </label>
                <div className="col-3">
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="col-3">
                  <label htmlFor="inputPassword5" className="form-label">
                    ALV
                  </label>
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-3 col-3">
                <div className="col-3">
                  <label htmlFor="inputPassword5" className="form-label">
                    RV
                  </label>
                  <input
                    type="text"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mb-3 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <button type="button" className="form-button-class">
                  Room Details
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <button type="button" className="form-button-class">
                  OK
                </button>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <button type="button" className="form-button-class">
                  Cancel
                </button>
              </div>
            </div>
            <div className="text-center ">
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded me-2"
              >
                {" "}
                <img src={preimg} className="saveimg" /> Back
              </button>
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded"
              >
                {" "}
                <img src={saveimg} className="saveimg " /> Save
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FlatDetails;
