import React from 'react';

import Sidebar from '../../Master/layout/Sidebar';
import Table from "../../Master/payment-reminder-master/Table";

const List = () => {
  return (
    <>
      <div>
      <section className='main-section main-sec-bg'>
            <div className='container-fluid'>
                <div className='row'>                    
                        <Sidebar />
                    <div className='col-xl-9 col-lg-8 col-md-12'>
                          <Table />
                    </div>
                </div>
            </div>
      </section>
    </div>
    </>
  )
}

export default List;
