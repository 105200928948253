import React from "react";

const PageNotFound = () => {
  return (
    <div>
      <h1 style={{ textAlign: "center" }}>404 Error</h1>
      <h1 style={{ textAlign: "center" }}>Page Not Found</h1>
    </div>
  );
};

export default PageNotFound;
