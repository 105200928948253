import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import tax from "../../assets/icons/tax.png";
import "../../Master/layout/Sidebar.css";
import sub from "../../assets/icons/sub.png";
import add1 from "../../assets/icons/add1.png";
import icon from "../../assets/icons/Icon.png";
import back from "../../assets/icons/back.png";
import group from "../../assets/icons/Group.png";
import document from "../../assets/icons/document.png";
import notice from "../../assets/icons/Notice.png";
import payment from "../../assets/icons/payement.png";
import fine from "../../assets/icons/fine.png";
import setting from "../../assets/icons/setting.png";
import layer from "../../assets/icons/Layer.png";
// import Tabels from '../Tabels/Tabels'
import countries from "../../assets/icons/countries.png";
import state from "../../assets/icons/state (2).png";
import District from "../../assets/icons/District.png";
import City from "../../assets/icons/city.png";
import Block from "../../assets/icons/Block.png";
import Ward from "../../assets/icons/ward.png";
import pincode from "../../assets/icons/pincode.png";
import property from "../../assets/icons/property.png";
import Title from "../../assets/icons/Title.png";
import PropertyHome from "../../assets/icons/PropertyHome.png";
import usage from "../../assets/icons/usage.png";
import Billing from "../../assets/icons/Billing.png";
import land from "../../assets/icons/land.png";
import tree from "../../assets/icons/tree.png";
import master from "../../assets/icons/master.png";
import room from "../../assets/icons/room.png";
import Glyph from "../../assets/icons/Glyph.png";
import lisence from "../../assets/icons/lisence.png";
import occupation from "../../assets/icons/occupation.png";
import construction from "../../assets/icons/construction.png";
// import CustomerList from "../customer-master/List";
// import DepartmentList from "../department/List";
// import TaxMasterList from "../Tax-master/List";
// import SubTaxList from "../sub-tax-master/List";
// import TaxList from "../Tax/List";

const Sidebar = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };

  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  return (
    <>
      <div className=" col-xl-3 col-lg-4 col-md-12 background-col p-0">
        <div
          className="tax-tabs nav mt-5 Menu-List nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className={
              headerText === "/rolemanagement"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/rolemanagement">
              <img src={setting} className="tab-image me-4" alt="" />
              Role Management
            </Link>
          </button>

          <button
            className={
              headerText === "/usermanagement"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/usermanagement">
              <img src={setting} className="tab-image me-4" alt="" />
              Users Management
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default Sidebar;
