import React from "react";
import HeaderBilling from "./HeaderBilling/HeaderBilling.js";
import { useContext } from "react";
import { Context } from "../../../utils/context.js";
import { useNavigate } from "react-router-dom";

const Header = () => {
  const { signin } = useContext(Context);
  const navigate = useNavigate();
  return <div>{signin ? <HeaderBilling /> : <></>}</div>;
};

export default Header;
