import React from "react";
import LoginPage from "./LoginPage/LoginPage.js";
import { useContext } from "react";
import { Context } from "../../../utils/context.js";
import { useNavigate } from "react-router-dom";

const Login = () => {
  const { signin, usertype } = useContext(Context);
  const navigate = useNavigate();

  return (
    <div>
      {signin ? (
        usertype === "Super Admin" ? (
          navigate("/dashboard")
        ) : usertype === "Data Entry" ? (
          navigate("/generatedbill")
        ) : (
          navigate("/data-entry")
        )
      ) : (
        <LoginPage />
      )}
    </div>
  );
};

export default Login;
