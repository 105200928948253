import React from "react";
import "../Recent/Recent.css";
import excel from "../../../StepForm/image/excel1.png";
import pdf from "../../../StepForm/image/pdf.png";
const Recent = () => {
  return (
    <>
      <section className="recent-tab-content">
        <div className="container">
          <div className="row mt-5 shadow-lg p-3 mb-5 bg-body rounded">
            <div className="col-md-11">
              <div className="Recent">
                <div className="row">
                  <div className=" col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-2 bd-rigth">
                    <div className="recent-background ">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <div className="updates m-3">
                            <p className="new text-center mt-1">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                          <div className="view mt-4 text-center">
                            <button type="button" className="btn-view">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">
                              Notice under Section 142(1)
                            </li>
                            <li className="shubham"> Shubham Kumar</li>
                            <li className="month">2 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Notice under Section 148</li>
                            <li className="shubham">Paresh More</li>
                            <li className="month">1 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">
                              Notice sent under Section 156
                            </li>
                            <li className="shubham">Akash More</li>
                            <li className="month">1 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">
                              Intimation under Section 143(1)
                            </li>
                            <li className="shubham">Amit Kumar</li>
                            <li className="month">3 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Notice under section 131</li>
                            <li className="shubham">Arun Raut</li>
                            <li className="month">4 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-2 bd-rigth">
                    <div className="recent-background">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <div className="updates m-3">
                            <p className="new text-center mt-1">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                          <div className="view mt-4 text-center">
                            <button type="button" className="btn-view">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Shubham Yadav</li>
                            <li className="shubham">
                              {" "}
                              Status -{" "}
                              <span style={{ color: "#01C822" }}>Accepted</span>
                            </li>
                            <li className="month">2 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Arjun Mane</li>
                            <li className="shubham">
                              Status -
                              <span style={{ color: "#01C822" }}>
                                {" "}
                                Accepted
                              </span>
                            </li>
                            <li className="month">1 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Kiran Kumari</li>
                            <li className="shubham">
                              Status -
                              <span style={{ color: "#FF5656" }}>
                                {" "}
                                Rejected
                              </span>
                            </li>
                            <li className="month">1 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Gopal Rao</li>
                            <li className="shubham">
                              Status -
                              <span style={{ color: "#FF5656" }}>
                                {" "}
                                Rejected
                              </span>
                            </li>
                            <li className="month">3 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Avanish More</li>
                            <li className="shubham">
                              Status -
                              <span style={{ color: "#01C822" }}>
                                {" "}
                                Accepetd
                              </span>
                            </li>
                            <li className="month">4 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-2">
                    <div className="recent-background">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <div className="updates m-3">
                            <p className="new text-center mt-1">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                          <div className="view mt-4 text-center">
                            <button type="button" className="btn-view">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Rohit Yadav</li>
                            <li className="shubham"> Consolidated invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all ">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Arjun Mane</li>
                            <li className="shubham">Retainer invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Kiran Kumari</li>
                            <li className="shubham">Timesheet invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Gopal Rao</li>
                            <li className="shubham">Final invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8">
                          <ul>
                            <li className="notice">Avanish More</li>
                            <li className="shubham">Overdue invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 my-auto">
                          <div className="view-all text-center ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-md-1">
              <div className="excelimg-class pt-3 text-center">
                <img src={excel} alt="..." className="download-excel-img" />
              </div>
              <div className="excelimg-class mt-2 pt-3 text-center">
                <img src={pdf} alt="..." />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Recent;
