import React, { useContext, useEffect, useState } from "react";
import saveimg from "../image/awesome-save.png";
import preimg from "../image/Icon awesome-backward.png";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
import circle from "../image/circle.png";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Step4 = ({ formData, handleChange }) => {
  const { postData, getData, editData, IMG_URL } = useContext(Context);

  const [t] = useTranslation("global");
  const navigate = useNavigate();
  const [formData1, setFormData1] = useState({
    property_no: "",
    sequence_no: "",
    old_property_no: "",
    ref_property_no: "",
    ward_id: "",
    zone_id: "",
    block_id: "",
    route_id: "",
    auth_status: "",
    property_status: "",
    authorised: false,
    legal: false,
    //
    roomdetails: [],
  });

  const [fields, setFields] = useState([
    {
      id: 1,
      flat_details_id: "",
      room_type_id: "",
      floor_id: "",
      room_width: "",
      room_length: "",
    },
  ]);

  // Function to add a new input field
  const addField = (type) => {
    const newId = getNextId(fields);

    const newField = {
      id: newId,
      flat_details_id: "",
      room_type_id: "",
      floor_id: "",
      room_width: "",
      room_length: "",
    };
    setFields([...fields, newField]);
  };

  // Function to delete an input field by ID
  const deleteField = (idToDelete) => {
    const updatedFields = fields.filter((field) => field.id !== idToDelete);
    setFields(updateIds(updatedFields));
  };

  // Function to get the next available ID
  const getNextId = (fieldArray) => {
    let maxId = 0;
    fieldArray.forEach((field) => {
      if (field.id > maxId) {
        maxId = field.id;
      }
    });
    return maxId + 1;
  };

  // Function to reassign sequential IDs
  const updateIds = (fieldArray) => {
    return fieldArray.map((field, index) => ({
      ...field,
      id: index + 1,
    }));
  };

  // Function to handle input changes
  const handleInputChange = (id, fieldName, value, e = null) => {
    const updatedFields = fields.map((field) => {
      if (field.id === id) {
        if (fieldName == "room_image") {
          return {
            ...field,
            [fieldName]: {
              image: value.files[0],
              imageUrl: URL.createObjectURL(value.files[0]),
            },
          };
        } else if (fieldName == "room_type_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "room_type_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "floor_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "floor_id",
              label: e?.label,
            },
          };
        } else if (fieldName == "flat_details_id") {
          return {
            ...field,
            [fieldName]: {
              value: value,
              name: "flat_details_id",
              label: e?.label,
            },
          };
        } else {
          return {
            ...field,
            [fieldName]: value,
          };
        }
      }
      return field;
    });
    setFields(updatedFields);
  };

  const [room, setRoom] = useState();
  const [floor, setFloor] = useState();
  const [flat, setFlat] = useState();

  const getAllData = async () => {
    const createbillid = Cookies.get("createbillid");

    if (createbillid) {
      const response = await editData("/api/getcreatebill4", {
        id: createbillid,
      });
      setFormData1(response?.data);
      setFields(response?.data?.roomdetails);

      {
        const res = await getData(
          `/api/get-flat-details-no-list/${createbillid}`
        );

        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.id,
              name: "flat_details_id",
              label: data.flat_no,
            });
          });
        }
        await setFlat(option);
      }
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // Somethingg

    if (!formData1.property_no) {
      errors.property_no = "Property no is required";
    }

    if (!formData1.sequence_no) {
      errors.sequence_no = "Sequence no is required";
    }

    if (!formData1.old_property_no) {
      errors.old_property_no = "Old property no is required";
    }

    if (!formData1.ref_property_no) {
      errors.ref_property_no = "Refrence property no is required";
    }

    if (!formData1.ward_id) {
      errors.ward_id = "Ward is required";
    }

    if (!formData1.zone_id) {
      errors.zone_id = "Zone is required";
    }

    fields.forEach((field, index) => {
      if (!field.room_width) {
        errors[`room_width-${field.id}`] = "Room Width is required";
      }

      if (!field.room_length) {
        errors[`room_length-${field.id}`] = "Room Length is required";
      }

      if (!field.room_type_id) {
        errors[`room_type_id-${field.id}`] = "Please Select Room Type ";
      }

      if (!field.floor_id) {
        errors[`floor_id-${field.id}`] = "Please Select Floor ";
      }

      if (!field.flat_details_id) {
        errors[`flat_details_id-${field.id}`] = "Please Select Flat No";
      }
    });

    return errors;
  };

  const handleBlur = async (e) => {
    let errors = {};

    const regexNumber = /^\d/;

    switch (e.target.name) {
      case "room_width":
        if (!e.target.value) {
          errors.room_width = "Room width is required";
        }
        //  else if (!regexNumber.test(e.target.value.trim())) {
        //   errors.room_width = "Enter a valid data";
        // }
        break;

      case "room_length":
        if (!e.target.value) {
          errors.room_length = "Room length is required";
        }
        //  else if (!regexNumber.test(e.target.value.trim())) {
        //   errors.room_length = "Enter a valid data";
        // }
        break;

      default:
        break;
    }

    await setErrors(errors);
  };

  const handleChange1 = (e) => {
    if (e?.name == "room_type_id" || e?.name == "floor_id") {
      setFormData1({ ...formData1, [e.name]: e });
    } else if (
      e.target.name === "manual_rv" ||
      e.target.name === "auto_rv" ||
      e.target.name === "alv" ||
      e.target.name === "rv" ||
      e.target.name === "exempted"
    ) {
      setFormData1({ ...formData1, [e.target.name]: e.target.checked });
    } else {
      setFormData1({ ...formData1, [e.target.name]: e.target.value });
    }
  };

  const handleClicks = async (name) => {
    switch (name) {
      case "room_type_id": {
        const res = await getData("/api/getroom");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.room_id,
              name: "room_type_id",
              label: data.name,
            });
          });
        }
        await setRoom(option);
        setFormData1({
          ...formData1,
          room_type_id: "",
        });
        break;
      }

      case "floor_id": {
        const res = await getData("/api/getfloor");
        const option = [];

        if (res.success) {
          res?.data?.map((data) => {
            option.push({
              value: data.floor_id,
              name: "floor_id",
              label: data.name,
            });
          });
        }
        await setFloor(option);
        setFormData1({
          ...formData1,
          floor_id: "",
        });
        break;
      }

      case "flat_details_id": {
        setFormData1({
          ...formData1,
          flat_details_id: "",
        });
        break;
      }

      default:
        break;
    }
  };

  useEffect(() => {
    setFormData1({ ...formData1, roomdetails: fields });
  }, [fields]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const data = new FormData();

        data.append("property_info_id", formData1?.property_info_id);

        let roomdetails = [];

        for (const d of formData1?.roomdetails || []) {
          roomdetails.push({
            flat_details_id: d.flat_details_id,
            room_type_id: d.room_type_id,
            floor_id: d.floor_id,
            room_width: d.room_width,
            room_length: d.room_length,
            image: d.room_image?.image || null,
          });

          if (d?.room_image?.image) {
            data.append(`image${roomdetails.length - 1}`, d.room_image.image);
          }
        }

        data.append("roomdetails", JSON.stringify(roomdetails));

        const response = await postData(
          "/createbill/roomdetails/addroomdetails",
          data
        );

        if (response.success) {
          Cookies.remove("createbillid");
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            // navigate("/tax-master/title");
            window.location.reload();
            navigate("/generatedbill");
          }, 2000);
          // handleChange("next");
        } else {
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  return (
    <>
      <div>
        <div className="row">
          <div className="tabepane-start">
            <div className="scroll-tab d-flex justify-content-between">
              <div className="button-holder-2">
                <button
                  className="btn text-start ms-2 previous-button"
                  onClick={() => handleChange("prev")}
                >
                  <img src={preimg} /> Back
                </button>
              </div>
              <div className="button-holder me-2">
                <button
                  className="btn btn-save "
                  // onClick={() => handleChange("submit")}
                  onClick={handleSubmit}
                >
                  <img src={saveimg} className="save-img me-2" />
                  Submit
                </button>
              </div>
            </div>
            <form className="" onSubmit={handleSubmit} role="form">
              {/* property form */}
              <div className="Property-Form">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.propertyno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        id="inputPassword5"
                        name="property_no"
                        className="form-control"
                        value={formData1?.property_no}
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.property_no && (
                        <span style={errorStyle}>{errors.property_no}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.sequenceno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="sequence_no"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.sequence_no}
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.sequence_no && (
                        <span style={errorStyle}>{errors.sequence_no}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.oldpropno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="old_property_no"
                        id="inputPassword5"
                        value={formData1?.old_property_no}
                        className="form-control"
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.old_property_no && (
                        <span style={errorStyle}>{errors.old_property_no}</span>
                      )}
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.refprono")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="ref_property_no"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.ref_property_no}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.ref_property_no && (
                        <span style={errorStyle}>{errors.ref_property_no}</span>
                      )}
                    </div>

                    {/* <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        Block <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="block_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.block_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.block_id && (
                        <span style={errorStyle}>{errors.block_id}</span>
                      )}
                    </div> */}

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.ward")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="ward_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.ward_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.ward_id && (
                        <span style={errorStyle}>{errors.ward_id}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.zone")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="zone_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.zone_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />

                      {errors.zone_id && (
                        <span style={errorStyle}>{errors.zone_id}</span>
                      )}
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.route")}{" "}
                        {/* <span className="reuiredstart">*</span> */}
                      </label>
                      <input
                        type="text"
                        name="route_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.route_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.route_id && (
                        <span style={errorStyle}>{errors.route_id}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.authstatus")}
                      </label>
                      <input
                        type="text"
                        name="auth_status"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.auth_status}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.auth_status && (
                        <span style={errorStyle}>{errors.auth_status}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.propertystatus")}
                      </label>
                      <input
                        type="text"
                        name="property_status"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.property_status}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.property_status && (
                        <span style={errorStyle}>{errors.property_status}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label className="form-label">
                        {t("firstform.propertyinfo.authorised")} ?
                      </label>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          aria-describedby="passwordHelpBlock"
                          name="authorised"
                          checked={formData1?.authorised}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.legal")}
                      </label>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          name="legal"
                          checked={formData1?.legal}
                          aria-describedby="passwordHelpBlock"
                          disabled
                        />
                      </div>
                    </div>
                  </div>
                  <div className="line-bottom-class mt-3"></div>
                  {/* Enter Room Details */}
                  <div>
                    <div className="mt-3">
                      <h2>Enter Room Details</h2>
                    </div>
                    {fields?.map((field, index) => (
                      <>
                        <div className="row">
                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("thirdform.flatdetails.flatno")}{" "}
                              <span className="reuiredstart">*</span>
                            </label>
                            <Select
                              className="custom-select mb-2"
                              value={field?.flat_details_id}
                              onFocus={() => {
                                handleClicks("flat_details_id", index);
                              }}
                              name={`flat_details_id-${field?.id}`}
                              id={`flat_details_id-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "flat_details_id",
                                  e.value,
                                  e
                                )
                              }
                              options={flat}
                            />

                            {errors[`flat_details_id-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`flat_details_id-${field?.id}`]}
                              </div>
                            )}
                          </div>

                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("fourform.roomtype")}{" "}
                              <span className="reuiredstart">*</span>
                            </label>
                            <Select
                              className="custom-select mb-2"
                              value={field?.room_type_id}
                              onFocus={() => {
                                handleClicks("room_type_id", index);
                              }}
                              name={`room_type_id-${field?.id}`}
                              id={`room_type_id-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "room_type_id",
                                  e.value,
                                  e
                                )
                              }
                              options={room}
                            />

                            {errors[`room_type_id-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`room_type_id-${field?.id}`]}
                              </div>
                            )}
                          </div>

                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("thirdform.flatdetails.floor_id")}{" "}
                              <span className="reuiredstart">*</span>
                            </label>
                            <Select
                              className="custom-select mb-2"
                              value={field?.floor_id}
                              onFocus={() => {
                                handleClicks("floor_id", index);
                              }}
                              name={`floor_id-${field?.id}`}
                              id={`floor_id-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "floor_id",
                                  e.value,
                                  e
                                )
                              }
                              options={floor}
                            />

                            {errors[`floor_id-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`floor_id-${field?.id}`]}
                              </div>
                            )}
                          </div>

                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("fourform.roomwidth")}{" "}
                              <span className="reuiredstart">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={field?.room_width}
                              name={`room_width-${field?.id}`}
                              id={`room_width-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "room_width",
                                  e.target.value
                                )
                              }
                            />
                            {errors[`room_width-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`room_width-${field?.id}`]}
                              </div>
                            )}
                          </div>
                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("fourform.roomlength")}{" "}
                              <span className="reuiredstart">*</span>
                            </label>
                            <input
                              type="number"
                              className="form-control"
                              value={field?.room_length}
                              name={`room_length-${field?.id}`}
                              id={`room_length-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "room_length",
                                  e.target.value
                                )
                              }
                            />
                            {errors[`room_length-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`room_length-${field?.id}`]}
                              </div>
                            )}
                          </div>

                          <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                            <label
                              htmlFor="inputPassword5"
                              className="form-label"
                            >
                              {t("fourform.roomimage")}{" "}
                              {/* <span className="reuiredstart">*</span> */}
                            </label>
                            <input
                              type="file"
                              className="form-control"
                              // value={field?.room_image}
                              name={`room_image-${field?.id}`}
                              id={`room_image-${field?.id}`}
                              onChange={(e) =>
                                handleInputChange(
                                  field?.id,
                                  "room_image",
                                  e.target
                                )
                              }
                            />
                            {errors[`room_image-${field?.id}`] && (
                              <div style={errorStyle}>
                                {errors[`room_image-${field?.id}`]}
                              </div>
                            )}
                          </div>
                          {(field?.room_image?.imageUrl ||
                            field?.room_image?.image) && (
                            <div className="col-xxl-4 col-xl-4 col-lg-4  col-md-4 col-sm-4 col-4 p-1">
                              <div className="image-preview-container">
                                <img
                                  src={
                                    field?.room_image?.imageUrl ||
                                    IMG_URL + field?.room_image?.image
                                  }
                                  alt="Preview"
                                  className="image-preview-container"
                                  style={{
                                    width: "120px",
                                    height: "120px",
                                  }}
                                />
                              </div>
                            </div>
                          )}
                        </div>

                        {field?.id === 1 ? (
                          <></>
                        ) : (
                          <div className="text-end mb-2">
                            <button
                              type="button"
                              className="form-button-class me-5"
                              onClick={() => deleteField(field?.id)}
                            >
                              Remove
                            </button>
                          </div>
                        )}
                      </>
                    ))}
                  </div>
                  <div className="text-end  mb-5">
                    {/* {formData1.fields.length === 1 ? (
                      <></>
                    ) : ( */}
                    <button
                      type="button"
                      className="form-button-class me-5"
                      onClick={() => {
                        addField(1);
                      }}
                    >
                      Add
                    </button>
                    {/* )} */}
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content created-bill-modal">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Created Bill Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Step4;
