import React from "react";
import { Bar } from "react-chartjs-2";
import { Canvas } from "react-three-fiber";
import { Chart as ChartJS, registerables } from "chart.js";

ChartJS.register(...registerables);
const GenerateTabFirst = () => {
  const data = {
    labels: [
      "jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        label: "Example Dataset",
        data: [10, 19, 8, 12, 10, 14, 6, 17, 5, 11, 9, 6],
        backgroundColor: [
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
          "rgba(7, 85, 95, 0.6)",
        ],
        innerWidth: [100],
        outerHeight: [80],
      },
    ],
  };
  return (
    <>
     
        <div className="row">
          <div className="col-md-9 col-xl-9  mt-2">
            <div className="graph">
              <div>
                <Bar data={data} />
              </div>
            </div>
          </div>
        </div>
      
    </>
  );
};

export default GenerateTabFirst;
