import React from 'react'
import ReportSidebar from '../report-sidebar/ReportSidebar';
import GovtTable from '../Govt-tax/GovtTable'
const Govt = () => {
  return (
    <>
      <section className='main-section main-sec-bg'>
        <div className='container-fluid'>
          <div className='row'>           
            <ReportSidebar />           
            <div className=' col-xl-9  col-lg-8  col-md-12'>
              <div className='row'>
                <GovtTable />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Govt