import React from "react";
import Sidebar from "../../Master/layout/Sidebar";
import upload from "../../assets/icons/upload.png";
import close from "../../assets/icons/close.png";
import circle from "../../assets/icons/circle.png";
import { Link } from "react-router-dom";
import "../document-master/Tax.css";
const Upload = () => {
  return (
    <>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div className="  col-xl-9 col-lg-8  col-md-12 mt-5">
              <div className="upload ">
                <div className="row">
                  <div className="col-md-10 mx-auto">
                    <form className="row g-3">
                      <div className="col-md-12 mb-3 ">
                        <div className="row">
                          <label
                            htmlFor="colFormLabelSm"
                            className="col-sm-2 col-form-label col-form-label-sm"
                          >
                            {" "}
                            Owner Name
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="colFormLabelSm"
                              placeholder=""
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 ">
                        <div className="row">
                          <label
                            htmlFor="colFormLabelSm"
                            className="col-sm-2 col-form-label col-form-label-sm"
                          >
                            Document Type*
                          </label>
                          <div className="col-sm-10">
                            <input
                              type="text"
                              className="form-control form-control-sm"
                              id="colFormLabelSm"
                              placeholder="Registration"
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mb-3 mt-5">
                        <div className="choose">
                          <div className="circle mt-3 text-center">
                            <img src={upload} className="up mt-3" alt="" />
                          </div>
                          <div className="file mt-3 text-center">
                            <input
                              type="file"
                              id="file-input"
                              name="file-input"
                            />
                            <label id="file-input-label" htmlFor="file-input">
                              Choose File/s
                            </label>
                            {/* <input type="file"  id="myFile"  /> */}
                          </div>
                          <div className="pdf mt-3 text-center mb-4">
                            <p className="regis mt-2">
                              registration _ rewieved.pdf{" "}
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12 mt-3">
                        <div className="mb-3">
                          <label
                            htmlFor="exampleFormControlTextarea1"
                            className="form-label"
                          >
                            Description
                          </label>
                          <textarea
                            className="form-control"
                            id="exampleFormControlTextarea1"
                            rows="3"
                          ></textarea>
                        </div>
                      </div>
                      <div className="row">
                        <div className="d-flex">
                          <div>
                            <Link to="/document-master">
                              {" "}
                              <button type="button" className="btn btn-save">
                                {" "}
                                <img src={close} className="sa me-2" alt="" />
                                Close
                              </button>
                            </Link>
                          </div>
                          <div>
                            <button
                              type="button"
                              className="btn btn-save"
                              data-bs-toggle="modal"
                              data-bs-target="#exampleModal2"
                            >
                              {" "}
                              <img src={upload} className="sa me-2" alt="" />
                              Upload
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- Modal --> */}
      <div className="upload-modal">
        <div
          className="modal fade"
          id="exampleModal2"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle text-center mt-4">
                  <img src={circle} className="circle-img" alt="" />
                </div>
                <h1 className="add-success text-center mt-3">
                  Document Uploaded
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Upload;
