import React, { useContext, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useState } from "react";
import save from "../../assets/icons/save.png";
import pencil from "../../assets/icons/pencil.png";
import backarrow from "../../assets/icons/back.png";
import circle from "../../assets/icons/circle.png";
import cancel from "../../assets/icons/cross.png";
import rigth from "../../assets/icons/rigth.png";

import Sidebar from "../../Master/layout/Sidebar";
import { Context } from "../../../../utils/context";

const Add = () => {
  const { postData, getData } = useContext(Context);
  const [propertype, setPropertyType] = useState([]);

  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    name: "",
    propertype: "",
    area: "",
    address: "",
    landmark: "",
    pincode: "",
    email: "",
    mobileno: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regex = /^[A-Za-z]+$/;
    const mobileRegex = /^\d{10}$/;

    if (!formData.name.trim()) {
      errors.name = "Owner name is required";
    } else if (!regex.test(formData?.name)) {
      errors.name = "Enter Valid Owner name";
    }
    if (!formData.propertype) {
      errors.propertype = "Select property type ";
    }
    if (!formData.area.trim()) {
      errors.area = "Area is required";
    }
    if (!formData.address.trim()) {
      errors.address = "Address is required";
    }
    if (!formData.landmark.trim()) {
      errors.landmark = "Landmark is required";
    }
    if (!formData.pincode.trim()) {
      errors.pincode = "Pincode is required";
    }
    if (!formData.email.trim()) {
      errors.email = "Email is required";
    }

    // if (!formData.mobileno) {
    //   errors.mobileno = "Mobile Number is required";
    // } else if (!mobileRegex.test(formData.mobileno)) {
    //   errors.mobileno = "Enter a valid 10-digit Mobile Number";
    // } else if (formData.mobileno < 1 || formData.mobileno > 999999999) {
    //   errors.mobileno = "Mobile Number must be between 1 and 999999999";
    // }

    if (!formData.mobileno) {
      errors.mobileno = "Mobile Number is required";
    } else if (!isValidMobileNumber(formData.mobileno)) {
      errors.mobileno = "Enter a valid 10-digit Mobile Number";
    }

    return errors;
  };

  function isValidMobileNumber(mobileNumber) {
    // Modify this function with your actual validation logic
    const mobileNumberRegex = /^\d{10}$/;
    return mobileNumberRegex.test(mobileNumber);
  }

  //const {postCustomerMaster} = useContext(Context);

  // Update a field
  // const handleInputChange = (e) => {
  //   const { name, value } = e.target;
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     [name]: value,
  //   }));

  // };

  // const handleInputChange = (e) => {
  //   setFormData({ ...formData, [e.target.name]: e.target.value });
  // };

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //validateForm()

    const validationErrors = validateForm();
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(
          "/customermaster/customermaster/customermaster/addcustomermaster",
          formData
        );
        // setData(response.data.heyyy);
        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/customer-list");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/customer-list`);
          }, 1000);
        }
        setFormData({
          name: "",
          propertype: "",
          area: "",
          address: "",
          landmark: "",
          pincode: "",
          email: "",
          mobileno: "",
        });
      } catch (error) {
        console.log(error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
  };

  const GetAllPropertytype = async () => {
    const response = await getData(
      "/master/taskmaster/propertytype/getallpropertype"
    );
    setPropertyType(response);
  };

  useEffect(() => {
    GetAllPropertytype();
  }, []);

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="back-btn-div">
                        <Link to="/customer-list">
                          <button className=" back-btn">
                            <img src={rigth} className="" alt="" /> Back
                          </button>
                        </Link>
                      </div>
                      <hr />
                      <div className="View">
                        <form className="form-background">
                          {/* //onSubmit={handleSubmit} */}
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Owner Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.name}
                                onChange={handleInputChange}
                                name="name"
                              />
                              {errors.name && (
                                <span style={errorStyle}>{errors.name}</span>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Property Type
                            </label>
                            <div className="col-sm-6">
                              {/* <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.propertype}
                                onChange={handleInputChange}
                                name="propertype"
                              /> */}
                              <select
                                name="propertype"
                                className="form-control"
                                onChange={handleInputChange}
                              >
                                <option value="" hidden>
                                  select propertype
                                </option>
                                {propertype?.data?.propertyType?.map(
                                  (ele, index) => {
                                    return (
                                      <option value={ele.id}>{ele.name}</option>
                                    );
                                  }
                                )}
                              </select>
                              {errors.propertype && (
                                <span style={errorStyle}>
                                  {errors?.propertype}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Area of Property (Sq.Ft.)
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.area}
                                onChange={handleInputChange}
                                name="area"
                              />
                              {errors.area && (
                                <span style={errorStyle}>{errors?.area}</span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Landmark
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.landmark}
                                onChange={handleInputChange}
                                name="landmark"
                              />
                              {errors.landmark && (
                                <span style={errorStyle}>
                                  {errors?.landmark}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Property Detailed Address
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.address}
                                onChange={handleInputChange}
                                name="address"
                              />
                              {errors.address && (
                                <span style={errorStyle}>
                                  {errors?.address}
                                </span>
                              )}
                            </div>
                          </div>

                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Pin Code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.pincode}
                                onChange={handleInputChange}
                                name="pincode"
                              />
                              {errors.pincode && (
                                <span style={errorStyle}>
                                  {errors?.pincode}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Email ID
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="email"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.email}
                                onChange={handleInputChange}
                                name="email"
                              />
                              {errors.email && (
                                <span style={errorStyle}>{errors?.email}</span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Mobile Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="tel"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.mobileno}
                                onChange={handleInputChange}
                                name="mobileno"
                                maxLength={10}
                              />
                              {errors.mobileno && (
                                <span style={errorStyle}>
                                  {errors.mobileno}
                                </span>
                              )}
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-md-6 text-end">
                              <div className="pencil">
                                <Link to="/customer-list">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                              </div>
                            </div>
                            <div className="col-md-6 justify-contend-end">
                              <div className="sa">
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className=" save-btn"
                                >
                                  {" "}
                                  <img src={save} className="sa " alt="" /> Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Billing Structure Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Customer Master Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Customer Master Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
