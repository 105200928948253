import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import sub from "../../assets/icons/data-entry.png";
import data from "../../assets/icons/Layer-2.png";
import asses from "../../assets/icons/Layer-1.png";
import tax from "../../assets/icons/ta- register.png";
import govt from "../../assets/icons/govttax.png";
import del from "../../assets/icons/deletion.png";

const ReportSidebar = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });

  return (
    <>
      <div className=" col-xl-3 col-lg-4 col-md-12 background-col p-0 ">
        <div
          className="tax-tabs nav mt-5 Menu-List nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className={
              headerText === "/data-entry" ? "nav-link active" : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="true"
          >
            <Link className="butt-decoration" to="/data-entry">
              <img src={sub} className="tab-image me-4" alt="" />
              Data Entry Report
            </Link>
          </button>

          <button
            className={
              headerText === "/data-entry-approved"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="true"
          >
            <Link className="butt-decoration" to="/data-entry-approved">
              <img src={sub} className="tab-image me-4" alt="" />
              Data Entry Approved Report
            </Link>
          </button>

          <button
            className={
              headerText === "/data-entry-rejected"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="true"
          >
            <Link className="butt-decoration" to="/data-entry-rejected">
              <img src={sub} className="tab-image me-4" alt="" />
              Data Entry Rejected Report
            </Link>
          </button>
          {/* <button
            className={
              headerText === "/Ward-Wise" ? "nav-link active" : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/Ward-Wise">
              <img src={data} className="tab-image me-4" alt="" />
              Wardwise List of Properties
            </Link>
          </button> 
          <button
            className={
              headerText === "/assesment" ? "nav-link active" : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/assesment">
              <img src={asses} className="tab-image me-4" alt="" />
              Assessment Register
            </Link>
          </button>
          <button
            className={
              headerText === "/tax-register"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/tax-register">
              <img src={tax} className="tab-image me-4" alt="" />
              Tax Register
            </Link>
          </button>
          <button
            className={
              headerText === "/govt" ? "nav-link active" : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/govt">
              <img src={govt} className="tab-image me-4" alt="" />
              Govt. Tax
            </Link>
          </button>
          <button
            className={
              headerText === "/deletion" ? "nav-link active" : "nav-link mb-2"
            }
            data-bs-toggle="pill"
            type="button"
            role="tab"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="/deletion">
              <img src={del} className="tab-image me-4" alt="" />
              Deletion Summary
            </Link>
          </button>*/}
        </div>
      </div>
    </>
  );
};

export default ReportSidebar;
