import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import sub from "../../assets/icons/sub.png";
import tax from "../../assets/icons/tax.png";
import add1 from "../../assets/icons/add1.png";
import group from "../../assets/icons/Group.png";
const Countersidebar = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <>
      <div className=" col-xl-3 col-lg-4 col-md-12 background-col p-0 ">
        <div
          className="tax-tabs nav mt-5 Menu-List nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          {/* <button
            className={
              headerText === "/counterwise"
                ? "nav-link active"
                : "nav-link mb-2"
            }
            id="v-pills-Detail-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-Detail"
            type="button"
            role="tab"
            aria-controls="v-pills-Detail"
            aria-selected="true"
          >
            <Link className="butt-decoration" to="/counterwise">
              <img src={add1} className="tab-image me-4" alt="" />
              Counter-wise Detail Report
            </Link>
          </button> */}
          {/* <button
            className={headerText === "" ? "nav-link active" : "nav-link mb-2"}
            id="v-pills-Summary-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-Summary"
            type="button"
            role="tab"
            aria-controls="v-pills-Summary"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="">
              <img src={sub} className="tab-image me-4" alt="" />
              Counter wise Summary Repot
            </Link>
          </button> */}
          {/* <button
            className={headerText === "" ? "nav-link active" : "nav-link mb-2"}
            id="v-pills-Receipt-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-Receipt"
            type="button"
            role="tab"
            aria-controls="v-pills-Receipt"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="">
              <img src={tax} className="tab-image me-4" alt="" />
              Counter & Receipt category wise{" "}
            </Link>
          </button> */}
          {/* <button
            className={headerText === "" ? "nav-link active" : "nav-link mb-2"}
            id="v-pills-Mode-tab"
            data-bs-toggle="pill"
            data-bs-target="#v-pills-Mode"
            type="button"
            role="tab"
            aria-controls="v-pills-Mode"
            aria-selected="false"
          >
            <Link className="butt-decoration" to="">
              <img src={group} className="tab-image me-4" alt="" />
              Mode of Payment
            </Link>
          </button> */}
        </div>
      </div>
    </>
  );
};

export default Countersidebar;
