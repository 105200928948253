import React, { useState, useEffect } from "react";
import "../Step/step.css";
import saveimg from "../image/awesome-save.png";
import preimg from "../image/Icon awesome-backward.png";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
import { useNavigate } from "react-router-dom";
import Cookies from "js-cookie";
import Select from "react-select";
import { useTranslation } from "react-i18next";

const Step2 = ({ formData, handleChange }) => {
  const { postData, getData, editData, minLength, maxLength } =
    useContext(Context);

  const [t, i18n] = useTranslation("global");

  const navigate = useNavigate();

  const [formData1, setFormData1] = useState({
    property_no: "",
    sequence_no: "",
    old_property_no: "",
    ref_property_no: "",
    ward_id: "",
    zone_id: "",
    block_id: "",
    route_id: "",
    auth_status: "",
    property_status: "",
    authorised: false,
    legal: false,
    //
    property_info_id: "",
    start_date: "",
    end_date: "",
    licence_no: "",
    property_age: "",
    land_type_id: "",
    land_use_type_id: "",
    creatificate_no: "",
    creatificate_completion_date: "",
    creatificate_receive_date: "",
    agency_name_id: "",
    licence_type_id: "",
  });

  // For Dropdown
  const [landType, setLandType] = useState();
  const [landUseType, setLandUseType] = useState();
  const [agencyName, setAgencyName] = useState();
  const [licenceType, setLicenceType] = useState();

  const getAllData = async () => {
    const createbillid = Cookies.get("createbillid");

    if (createbillid) {
      const response = await editData("/api/getcreatebill2", {
        id: createbillid,
      });
      setFormData1(response.data);
    }
  };

  useEffect(() => {
    getAllData();
  }, []);

  const handleClicks = async (name) => {
    switch (name) {
      // case "land_type_id": {
      //   const res = await getData("/api/getlandtype");
      //   const option = [];

      //   if (res.success) {
      //     res?.data?.map((data) => {
      //       option.push({
      //         value: data.landtype_id,
      //         name: "land_type_id",
      //         label: data.name,
      //       });
      //     });
      //   }
      //   setLandType(option);
      //   setFormData1({
      //     ...formData1,
      //     land_type_id: "",
      //     land_use_type_id: "",
      //   });
      //   break;
      // }

      // case "land_use_type_id": {
      //   const res = await getData("/api/getlandusetype");
      //   const option = [];
      //   if (res.success) {
      //     res?.data?.map((data) => {
      //       option.push({
      //         value: data.landusetype_id,
      //         name: "land_use_type_id",
      //         label: data.name,
      //       });
      //     });
      //   }
      //   await setLandUseType(option);
      //   setFormData1({
      //     ...formData1,
      //     land_use_type_id: "",
      //   });
      //   break;
      // }

      // case "agency_name_id": {
      //   const res = await getData("/api/getagencyname");
      //   const option = [];

      //   if (res.success) {
      //     res?.data?.map((data) => {
      //       option.push({
      //         value: data.agancy_name_id,
      //         name: "agency_name_id",
      //         label: data.name,
      //       });
      //     });
      //   }
      //   await setAgencyName(option);
      //   setFormData1({
      //     ...formData1,
      //     agency_name_id: "",
      //   });
      //   break;
      // }

      // case "licence_type_id": {
      //   const res = await getData("/api/getlicencetype");
      //   const option = [];
      //   if (res.success) {
      //     res?.data?.map((data) => {
      //       option.push({
      //         value: data.licencetype_id,
      //         name: "licence_type_id",
      //         label: data.name,
      //       });
      //     });
      //   }
      //   await setLicenceType(option);
      //   setFormData1({
      //     ...formData1,
      //     licence_type_id: "",
      //   });
      //   break;
      // }

      default:
        break;
    }
  };

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    // Somethingg

    if (!formData1.property_no) {
      errors.property_no = "Property no is required";
    }

    if (!formData1.sequence_no) {
      errors.sequence_no = "Sequence no is required";
    }

    if (!formData1.old_property_no) {
      errors.old_property_no = "Old property no is required";
    }

    if (!formData1.ref_property_no) {
      errors.ref_property_no = "Refrence property no is required";
    }

    // if (!formData1.pincode_id) {
    //   errors.pincode_id = "pincode is required";
    // }

    if (!formData1.ward_id) {
      errors.ward_id = "Ward is required";
    }

    if (!formData1.zone_id) {
      errors.zone_id = "Zone is required";
    }

    // if (!formData1.zone_id) {
    //   errors.zone_id = "Zone is required";
    // }
    // if (!formData1.block_id) {
    //   errors.block_id = "Block is required";
    // }
    // if (!formData1.route_id) {
    //   errors.route_id = "Route is required";
    // }
    // if (!formData1.auth_status) {
    //   errors.auth_status = "Auth Status is required";
    // }
    // if (!formData1.property_status) {
    //   errors.property_status = "Property Status is required";
    // }

    // Somethingg

    // if (!formData1.start_date) {
    //   errors.start_date = "Start Date is required";
    // }

    // if (!formData1.end_date) {
    //   errors.end_date = "End Date is required";
    // }
    // const regex = /^\d+$/;

    // if (!formData1.licence_no) {
    //   errors.licence_no = "Licence No is required";
    // } else if (!regex.test(formData1.licence_no)) {
    //   errors.licence_no = "Enter a valid data";
    // }

    // if (!formData1.property_age) {
    //   errors.property_age = "Property Age is required";
    // } else if (!regex.test(formData1.property_age)) {
    //   errors.property_age = "Enter a valid data";
    // }

    // const regex2 = /^[a-zA-Z0-9]+$/;

    // if (!formData1.creatificate_no) {
    //   errors.creatificate_no = "Creatificate No is required";
    // } else if (!regex2.test(formData1.creatificate_no)) {
    //   errors.creatificate_no = "Enter a valid data";
    // } else if (
    //   formData1.creatificate_no.trim().length < minLength ||
    //   formData1.creatificate_no.trim().length > maxLength
    // ) {
    //   errors.creatificate_no = `Creatificate No must be between ${minLength} and ${maxLength} characters`;
    // }

    // if (!formData1.land_type_id) {
    //   errors.land_type_id = "Please Select Land Type";
    // }

    // if (!formData1.land_use_type_id) {
    //   errors.land_use_type_id = "Please Select Land Use Type";
    // }

    // if (!formData1.creatificate_completion_date) {
    //   errors.creatificate_completion_date =
    //     "Creatificate Completion Date is required";
    // }

    // if (!formData1.creatificate_receive_date) {
    //   errors.creatificate_receive_date =
    //     "Creatificate Receive Date is required";
    // }

    // if (!formData1.agency_name_id) {
    //   errors.agency_name_id = "Please Select Agency Name";
    // }

    // if (!formData1.licence_type_id) {
    //   errors.licence_type_id = "Please Select Licence Type";
    // }

    return errors;
  };

  const handleBlur = async (e) => {
    let errors = {};

    const regexNumber = /^\d/;
    const regexContact = /^\d{10}$/;
    const regexAdhar = /^\d{12}$/;
    const regexAlfaNumric = /^[A-Za-z0-9]+(\s[A-Za-z0-9]+)*$/;
    const regexAlfa = /^[A-Za-z]+(\s[A-Za-z]+)*$/;
    const regexemail = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    const regex2 = /^[a-zA-Z0-9]+$/;

    switch (e.target.name) {
      // case "licence_no":
      //   if (!e.target.value) {
      //     errors.licence_no = "Licence No is required";
      //   } else if (!regexNumber.test(e.target.value)) {
      //     errors.licence_no = "Enter a valid data";
      //   }
      //   break;

      // case "property_age":
      //   if (!e.target.value) {
      //     errors.property_age = "Property Age is required";
      //   } else if (!regexNumber.test(e.target.value)) {
      //     errors.property_age = "Enter a valid data";
      //   }
      //   break;

      // case "creatificate_no":
      //   if (!e.target.value) {
      //     errors.creatificate_no = "Creatificate No is required";
      //   } else if (!regex2.test(e.target.value)) {
      //     errors.creatificate_no = "Enter a valid data";
      //   } else if (
      //     e.target.value.trim().length < minLength ||
      //     e.target.value.trim().length > maxLength
      //   ) {
      //     errors.creatificate_no = `Creatificate No must be between ${minLength} and ${maxLength} characters`;
      //   }
      //   break;

      default:
        break;
    }

    await setErrors(errors);
  };

  const handleChange1 = (e) => {
    if (
      e?.name == "land_type_id" ||
      e?.name == "land_use_type_id" ||
      e?.name == "agency_name_id" ||
      e?.name == "licence_type_id"
    ) {
      setFormData1({ ...formData1, [e.name]: e });
    } else {
      setFormData1({ ...formData1, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await postData(
          "/createbill/constructiondetails/addconstructiondetails",
          formData1
        );
        if (response.success) {
          handleChange("next");
        } else {
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
    fontSize: "11px",
  };

  const successStyle = {
    color: "green",
    marginLeft: "5px",
    fontSize: "11px",
  };

  return (
    <div className="container">
      <div className="row">
        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
          <div className="tabepane-start">
            <div className="scroll-tab d-flex justify-content-between">
              <div className="button-holder-2">
                <button
                  className=" btn text-start ms-2 previous-button"
                  onClick={() => handleChange("prev")}
                >
                  <img src={preimg} /> Back
                </button>
              </div>
              <div className="button-holder me-2">
                <button
                  className="btn btn-save"
                  // onClick={() => handleChange("next")}
                  onClick={handleSubmit}
                >
                  <img src={saveimg} className="save-img me-2" />
                  Save
                </button>
              </div>
            </div>
            <form className="" onSubmit={handleSubmit} role="form">
              <div className="Property-Form">
                <div className="container">
                  <div className="row mt-4">
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.propertyno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        id="inputPassword5"
                        name="property_no"
                        className="form-control"
                        value={formData1?.property_no}
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.property_no && (
                        <span style={errorStyle}>{errors.property_no}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.sequenceno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="sequence_no"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.sequence_no}
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.sequence_no && (
                        <span style={errorStyle}>{errors.sequence_no}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.oldpropno")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="old_property_no"
                        id="inputPassword5"
                        value={formData1?.old_property_no}
                        className="form-control"
                        aria-describedby="passwordHelpBlock"
                        disabled
                      />
                      {errors.old_property_no && (
                        <span style={errorStyle}>{errors.old_property_no}</span>
                      )}
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.refprono")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="ref_property_no"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.ref_property_no}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.ref_property_no && (
                        <span style={errorStyle}>{errors.ref_property_no}</span>
                      )}
                    </div>

                    {/* <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        Block <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="block_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.block_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.block_id && (
                        <span style={errorStyle}>{errors.block_id}</span>
                      )}
                    </div> */}

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.ward")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="ward_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.ward_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.ward_id && (
                        <span style={errorStyle}>{errors.ward_id}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.zone")}{" "}
                        <span className="reuiredstart">*</span>
                      </label>
                      <input
                        type="text"
                        name="zone_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.zone_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />

                      {errors.zone_id && (
                        <span style={errorStyle}>{errors.zone_id}</span>
                      )}
                    </div>

                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.route")}{" "}
                        {/* <span className="reuiredstart">*</span> */}
                      </label>
                      <input
                        type="text"
                        name="route_id"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.route_id}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.route_id && (
                        <span style={errorStyle}>{errors.route_id}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.authstatus")}
                      </label>
                      <input
                        type="text"
                        name="auth_status"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.auth_status}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.auth_status && (
                        <span style={errorStyle}>{errors.auth_status}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.propertystatus")}
                      </label>
                      <input
                        type="text"
                        name="property_status"
                        id="inputPassword5"
                        className="form-control"
                        value={formData1?.property_status}
                        aria-describedby="passwordHelpBlock"
                        placeholder=" "
                        disabled
                      />
                      {errors.property_status && (
                        <span style={errorStyle}>{errors.property_status}</span>
                      )}
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label className="form-label">
                        {t("firstform.propertyinfo.authorised")} ?
                      </label>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          aria-describedby="passwordHelpBlock"
                          name="authorised"
                          checked={formData1?.authorised}
                          disabled
                        />
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
                      <label htmlFor="inputPassword5" className="form-label">
                        {t("firstform.propertyinfo.legal")}
                      </label>
                      <div className="col-2">
                        <input
                          type="checkbox"
                          name="legal"
                          checked={formData1?.legal}
                          aria-describedby="passwordHelpBlock"
                          disabled
                        />
                      </div>
                    </div>
                  </div>

                  <div className="line-bottom-class mt-3 "></div>
                  {/* construction */}
                  <div>
                    <div>
                      <h2 className="mt-3">Construction Information</h2>
                    </div>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.constructioninformation.startdate")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="date"
                          id="inputPassword5"
                          className="form-control"
                          max={new Date().toISOString().split("T")[0]}
                          value={
                            formData1?.start_date
                              ? new Date(formData1.start_date)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          name="start_date"
                          onChange={handleChange1}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.start_date && (
                          <span style={errorStyle}>{errors.start_date}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.constructioninformation.enddate")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="date"
                          id="inputPassword5"
                          max={new Date().toISOString().split("T")[0]}
                          className="form-control"
                          name="end_date"
                          value={
                            formData1?.end_date
                              ? new Date(formData1.end_date)
                                  .toISOString()
                                  .split("T")[0]
                              : ""
                          }
                          onChange={handleChange1}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.end_date && (
                          <span style={errorStyle}>{errors.end_date}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.constructioninformation.licenseno")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          name="licence_no"
                          value={formData1?.licence_no}
                          onChange={handleChange1}
                          onInput={handleBlur}
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.licence_no && (
                          <span style={errorStyle}>{errors.licence_no}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.constructioninformation.propertyage")}{" "}
                          {/* <span className="reuiredstart">*</span> */}
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          name="property_age"
                          value={formData1?.property_age}
                          onChange={handleChange1}
                          onInput={handleBlur}
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                        />
                        {errors.property_age && (
                          <span style={errorStyle}>{errors.property_age}</span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="line-bottom-class mt-3 "></div>
                  {/* Land Information */}
                  <div>
                    {/* <div>
                      <h2 className="mt-3">Land Information</h2>
                    </div>
                    <div className="row">
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.landinformation.landtype")}{" "}
                        </label>
                        <Select
                          name="land_type_id"
                          className="custom-select mb-2"
                          value={formData1?.land_type_id}
                          onFocus={() => {
                            handleClicks("land_type_id");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={landType}
                        />

                        {errors.land_type_id && (
                          <span style={errorStyle}>{errors.land_type_id}</span>
                        )}
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          {t("secondform.landinformation.landusetype")}{" "}
                        </label>
                        <Select
                          name="land_use_type_id"
                          className="custom-select mb-2"
                          value={formData1?.land_use_type_id}
                          onFocus={() => {
                            handleClicks("land_use_type_id");
                          }}
                          onChange={(event) => {
                            handleChange1(event);
                          }}
                          options={landUseType}
                        />

                        {errors.land_use_type_id && (
                          <span style={errorStyle}>
                            {errors.land_use_type_id}
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="line-bottom-class mt-3 "></div> */}

                    {/* License Certificate Information */}
                    <div>
                      <div className="row">
                        <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                          <h2 className="mt-3 ">
                            License Certificate Information{" "}
                            {/* <span className="reuiredstart">*</span> */}
                          </h2>
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t(
                              "secondform.licensecertificateinformation.certificateno"
                            )}{" "}
                            {/* <span className="reuiredstart">*</span> */}
                          </label>
                          <input
                            type="text"
                            id="inputPassword5"
                            className="form-control"
                            name="creatificate_no"
                            onChange={handleChange1}
                            onInput={handleBlur}
                            value={formData1?.creatificate_no}
                            aria-describedby="passwordHelpBlock"
                          />
                          {errors.creatificate_no && (
                            <span style={errorStyle}>
                              {errors.creatificate_no}
                            </span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t(
                              "secondform.licensecertificateinformation.certcompletiondate"
                            )}{" "}
                            {/* <span className="reuiredstart">*</span> */}
                          </label>
                          <input
                            type="date"
                            id="inputPassword5"
                            className="form-control"
                            max={new Date().toISOString().split("T")[0]}
                            name="creatificate_completion_date"
                            onChange={handleChange1}
                            value={
                              formData1?.creatificate_completion_date
                                ? new Date(
                                    formData1.creatificate_completion_date
                                  )
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            aria-describedby="passwordHelpBlock"
                          />
                          {errors.creatificate_completion_date && (
                            <span style={errorStyle}>
                              {errors.creatificate_completion_date}
                            </span>
                          )}
                        </div>
                        <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t(
                              "secondform.licensecertificateinformation.certreceivedate"
                            )}{" "}
                            {/* <span className="reuiredstart">*</span> */}
                          </label>
                          <input
                            type="date"
                            id="inputPassword5"
                            max={new Date().toISOString().split("T")[0]}
                            className="form-control"
                            name="creatificate_receive_date"
                            onChange={handleChange1}
                            value={
                              formData1?.creatificate_receive_date
                                ? new Date(formData1.creatificate_receive_date)
                                    .toISOString()
                                    .split("T")[0]
                                : ""
                            }
                            aria-describedby="passwordHelpBlock"
                          />
                          {errors.creatificate_receive_date && (
                            <span style={errorStyle}>
                              {errors.creatificate_receive_date}
                            </span>
                          )}
                        </div>
                        {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t(
                              "secondform.licensecertificateinformation.agencyname"
                            )}
                          </label>
                          <Select
                            name="agency_name_id"
                            className="custom-select mb-2"
                            value={formData1?.agency_name_id}
                            onFocus={() => {
                              handleClicks("agency_name_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={agencyName}
                          />

                          {errors.agency_name_id && (
                            <span style={errorStyle}>
                              {errors.agency_name_id}
                            </span>
                          )}
                        </div> */}
                        {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1 mb-5">
                          <label
                            htmlFor="inputPassword5"
                            className="form-label"
                          >
                            {t(
                              "secondform.licensecertificateinformation.licensetype"
                            )}{" "}
                           
                          </label>
                          <Select
                            name="licence_type_id"
                            className="custom-select mb-2"
                            value={formData1?.licence_type_id}
                            onFocus={() => {
                              handleClicks("licence_type_id");
                            }}
                            onChange={(event) => {
                              handleChange1(event);
                            }}
                            options={licenceType}
                          />

                          {errors.licence_type_id && (
                            <span style={errorStyle}>
                              {errors.licence_type_id}
                            </span>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Step2;
