import React from "react";
import "../DashboardContent/DashboardContent.css";
import GenerateTabFirst from "../generatetab-first/GenerateTabFirst";
import { Link } from "react-router-dom";
import { Doughnut } from "react-chartjs-2";
import { Line } from "react-chartjs-2";
import CanvasJSReact from "@canvasjs/react-charts";
import Heading from "../../Header/Heading/Heading";
//var CanvasJSReact = require('@canvasjs/react-charts');

var CanvasJS = CanvasJSReact.CanvasJS;
var CanvasJSChart = CanvasJSReact.CanvasJSChart;
const DashboardContent = () => {
  const options = {
    animationEnabled: true,

    subtitles: [
      {
        text: "71% Positive",
        verticalAlign: "center",
        fontSize: 24,
        dockInsidePlotArea: true,
      },
    ],

    data: [
      {
        type: "doughnut",
        showInLegend: true,

        dataPoints: [
          { name: "", y: 5 },
          { name: "", y: 31 },
          { name: "", y: 40 },
          { name: "", y: 17 },
          { name: "", y: 7 },
        ],
      },
    ],
  };
  // Doughnut data
  const data = {
    labels: [],
    datasets: [
      {
        data: [10], // Replace these values with your data
        backgroundColor: ["#07555F"], // Colors for the segments
        hoverBackgroundColor: ["50"],
      },
    ],
  };

  const data1 = {
    labels: [
      "jan",
      "Feb",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ],
    datasets: [
      {
        data: [30, 25, 45, 56, 80, 40, 34, 20, 40, 60, 21, 56],
        backgroundColor: ["#07555F"],
      },
    ],
  };

  return (
    <>
      {/* Main Section Started */}

      {/* <Heading  title="Super Admin Dashboard " /> */}
      <section className="Dashboard">
        <div className="container-fluid set2">
          <div className="row">
            {/* tab section Started */}
            <div className="col-md-3 col-lg-3 col-xl-4 col-xxl-3">
              <div className="Tab-section">
                <ul className="nav nav-pills">
                  <div className="tabs-section">
                    <div className="row mx-auto">
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link
                            className="nav-link "
                            aria-current="page"
                            href="#"
                          >
                            Total <br></br>Generated Bill<br></br> (34)
                          </Link>
                        </li>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Total <br></br>Notice Generated <br></br>(20)
                          </Link>
                        </li>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Total<br></br> Tax Collection <br></br>(52)
                          </Link>
                        </li>
                      </div>
                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Total <br></br>Ward Covered <br></br>(15)
                          </Link>
                        </li>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Total<br></br> Arrears Entry<br></br> (15)
                          </Link>
                        </li>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Total <br></br>Invoices Generated <br></br>(26) per
                          </Link>
                        </li>
                      </div>

                      <div className="col-xxl-6 col-xl-6 col-lg-12 col-md-12 col-6 mt-3">
                        <li className="nav-item">
                          <Link className="nav-link" href="#">
                            Recent Activity
                          </Link>
                        </li>
                      </div>
                    </div>
                  </div>
                </ul>
              </div>
            </div>
            {/* tab section end */}

            {/* graph section started */}
            <div className="col-md-9 col-xl-8 col-xxl-9">
              <div className="Middel-Content">
                <div className="row ">
                  <ul
                    className="nav nav-pills mb-3 justify-content-end"
                    id="pills-tab"
                    role="tablist"
                  >
                    <li className="nav-item mt-3 me-3" role="presentation">
                      <button
                        className="nav-link "
                        id="pills-home-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-home"
                        type="button"
                        role="tab"
                        aria-controls="pills-home"
                        aria-selected="true"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault1"
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault1"
                          >
                            <p className="report">Daily Report</p>
                          </label>
                        </div>
                      </button>
                    </li>

                    <li className="nav-item mt-3" role="presentation">
                      <button
                        className="nav-link active"
                        id="pills-profile-tab"
                        data-bs-toggle="pill"
                        data-bs-target="#pills-profile"
                        type="button"
                        role="tab"
                        aria-controls="pills-profile"
                        aria-selected="false"
                      >
                        <div className="form-check">
                          <input
                            className="form-check-input"
                            type="radio"
                            name="flexRadioDefault"
                            id="flexRadioDefault2"
                            defaultChecked
                          />
                          <label
                            className="form-check-label"
                            htmlFor="flexRadioDefault2"
                          >
                            <p className="report">Monthly Report</p>
                          </label>
                        </div>
                      </button>
                    </li>
                  </ul>
                  <div className="tab-content" id="pills-tabContent">
                    <div
                      className="tab-pane fade show active"
                      id="pills-home"
                      role="tabpanel"
                      aria-labelledby="pills-home-tab"
                      tabIndex="0"
                    >
                      <div className="row">
                        <div className="col-md-12 col-xl-7 mt-3">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">Total Generated Bill</p>
                            </div>
                            <div className="linear">
                              <Line data={data1} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-5 mt-3">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">Total Notice Generated</p>
                            </div>
                            <div className="dough text-center">
                              <Doughnut data={data} />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-md-12 col-xl-5 mt-3">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">Total Tax Collected</p>
                            </div>
                            <div className="thirdgraph">
                              <CanvasJSChart options={options} />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-12 col-xl-7 mt-3 ">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">
                                Total Invoices Generated per month/day
                              </p>
                            </div>
                            <GenerateTabFirst />
                          </div>
                        </div>
                      </div>

                      <div className="row mt-3">
                        <div className="col-xl-7  col-md-12 mt-3">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">Total Arrears Entry</p>
                            </div>
                            <div className="linear">
                              <Line data={data1} />
                            </div>
                          </div>
                        </div>

                        <div className="col-xl-5 col-md-12  mt-3">
                          <div className="generated">
                            <div className="grey-header">
                              <p className="bill">
                                Total Invoices Generated per month/day
                              </p>
                            </div>
                            <div className="bargraph">
                              <GenerateTabFirst />
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="pills-profile"
                      role="tabpanel"
                      aria-labelledby="pills-profile-tab"
                      tabIndex="0"
                    >
                      ...
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* graph section end */}
          </div>
        </div>
      </section>
    </>
  );
};

export default DashboardContent;
