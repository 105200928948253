import React from "react";
import "../../Master/Tabels/Tabels.css";
// import { Link } from "react-router-dom";
import search from "../../assets/icons/search.png";
const AssesmentTable = () => {
  return (
    <>
      <section className="Tabels tab-radio mt-5">
        <div className="col-lg-10 mx-auto">
          <form>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Assessment Number
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="inputEmail3"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Old Assessment Number
              </label>
              <div className="col-sm-9">
                <input
                  type="number"
                  className="form-control"
                  id="inputEmail3"
                />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Assessment Year
              </label>
              <div className="col-sm-9">
                <input type="year" className="form-control" id="inputEmail3" />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Owner Name
              </label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="inputEmail3" />
              </div>
            </div>
            <div className="row mb-3">
              <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                Property Type
              </label>
              <div className="col-sm-9">
                <input type="text" className="form-control" id="inputEmail3" />
              </div>
            </div>
            <div className="Search-holder text-center">
              <button className="btn btn-sea">
                {" "}
                <img src={search} className="me-2" alt="" />
                search{" "}
              </button>
            </div>
          </form>
        </div>
      </section>
    </>
  );
};

export default AssesmentTable;
