import React, { useContext, useEffect, useState } from "react";
import "../HistoryView/HistoryView.css";
import { Context } from "../../../../utils/context";
import { useParams } from "react-router-dom";

const HistoryView = () => {
  const { id } = useParams();
  const { getData } = useContext(Context);

  const [data, setData] = useState([]);
  const GetdetailData = async () => {
    const response = await getData(
      `/createbill/propertyinfo/getallpropertydetailsbyid/${id}`
    );
    await setData(response.data);
  };

  useEffect(() => {
    GetdetailData();
  }, []);
  return (
    <>
      <section className="history-view">
        <div className="container">
          <div className="row">
            <div className="col-md-12 text-center mt-5 mb-5">
              <h1 className="history-title">History</h1>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 ">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                disabled
                aria-describedby="passwordHelpBlock"
                value={data?.property_no}
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                disabled
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.sequence_no}
                // placeholder="00004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                disabled
                aria-describedby="passwordHelpBlock"
                // placeholder="00004"
                value={data?.old_property_no}
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Property No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                disabled
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.ref_property_no}
              />
            </div>
            {/* 2nd row */}
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              {/* <select id="inputState" className="form-select">
                <option selected>Block 1</option>
                <option>Block 2</option>
                <option>Block 3</option>
                <option>Block 4</option>
              </select> */}
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.block_id}
                disabled
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                disabled
                aria-describedby="passwordHelpBlock"
                value={data?.ward_id}
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Pincode
              </label>
              {/* <select id="inputState" className="form-select">
                <option selected>Zone 1</option>
                <option>Zone 2</option>
                <option>Zone 3</option>
                <option>Zone 4</option>
              </select> */}
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="zone"
                disabled
                value={data?.pincode_id}
                //value={data?.zone_id}
              />
            </div>

            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              {/* <select id="inputState" className="form-select">
                <option selected>Route 1</option>
                <option>Route 2</option>
                <option>Route 3</option>
                <option>Route 4</option>
              </select> */}
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.route_id}
                disabled
              />
            </div>
            {/* <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label"></label>
              <select id="inputState" className="form-select">
                <option selected>Route 1</option>
                <option>Route 2</option>
                <option>Route 3</option>
                <option>Route 4</option>
              </select>
            </div> */}
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Return Type
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                // placeholder="00004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Financial Year
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="2022-2023"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Tax Period
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="March"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                ARN
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Date of filling
              </label>
              <input
                type="date"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.createdAt}
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Status
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="Filed-Valied"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4">
              <label htmlFor="inputPassword5" className="form-label">
                Mode of Filling
              </label>
              <input
                type="number"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="Online"
              />
            </div>
            <div className="Approved-button text-center mt-5 ">
              <button className="btn-approved p-1">Approved</button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default HistoryView;
