import React from "react";

// import { Link } from 'react-router-dom';
import ReportSidebar from "../report-sidebar/ReportSidebar";
import DataView from "./DataView";
const Dataentry = () => {
  return (
    <>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <ReportSidebar />
            <div className=" col-xl-9  col-lg-8  col-md-12">
              <div className="row">
                <DataView />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Dataentry;
