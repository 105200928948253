import { useState } from "react";
import "../Popup/Popup.css";
import { Link } from "react-router-dom";
import plus from "../Popup/image/Group 19795.png";
import historyimg from "../Popup/image/history.png";
import createimg from "../Popup/image/create-bill.png";

const Popup = () => {
  const [isActive, setIsActive] = useState(false);
  const handleClick = () => {
    setIsActive(!isActive);
  };
  return (
    <>
      <section className="services-popup-main">
        <div>
          <div className={isActive ? "show-history-btn" : "hide-history-btn"}>
            <div className="">
              <button
                type="button"
                className="btn history-btn"
                data-bs-toggle="modal"
                data-bs-target="#staticBackdrop"
              >
                History{" "}
                <img src={historyimg} className="historyimg" alt="..." />
              </button>
            </div>
            <div className="d-flex">
              <Link to="/form">
                <button
                  type="button"
                  className="btn create-btn"
                  data-bs-toggle=" "
                  data-bs-target=" "
                >
                  Create Bill{" "}
                  <img src={createimg} className="createimg" alt="..." />
                </button>
              </Link>
            </div>
          </div>
        </div>
        <button
          onClick={handleClick}
          type="button"
          className="btn popup-button"
          data-bs-toggle=" "
          data-bs-target=" "
        >
          <img src={plus} className="plusimg" alt="..." />
        </button>
      </section>
      {/* History modal */}
      <div className="history-modal">
        <div
          className="modal fade"
          id="staticBackdrop"
          data-bs-backdrop="static"
          data-bs-keyboard="false"
          tabindex="-1"
          aria-labelledby="staticBackdropLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-dialog-centered modal-dialog-scrollable">
            <div className="modal-content">
              <div className="modal-header text-center">
                <h3 className="modal-title fs-5 " id="staticBackdropLabel">
                  History
                </h3>
                <button
                  type="button"
                  className="btn-close"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                ></button>
              </div>
              <div className="modal-body">
                <section className="Property-Form">
                  <div className="container">
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Property No.
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="111100004"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Sequence No.
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="00004"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Old Prop. No.
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="0122200546"
                        />
                      </div>
                      {/* 2nd */}
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Ref Property No.
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="0122200546"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Ward
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="A"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Zone
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Zone 1"
                        />
                      </div>
                      {/* 3rd */}
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Block
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Block 1"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Route
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Route 1"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Return Type
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="A"
                        />
                      </div>
                      {/* 4th */}
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Financial Year
                        </label>
                        <input
                          type="year"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="2022-2023"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Tax Period
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="March"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          ARN
                        </label>
                        <input
                          type="number"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="111100004"
                        />
                      </div>
                      {/* 5th */}
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Date of Filling
                        </label>
                        <input
                          type="date"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="08/11/2022"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Status
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Filed-Valied"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Mode of Filling
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                          placeholder="Online"
                        />
                      </div>
                    </div>
                  </div>
                </section>
              </div>
              <div className="modal-footer justify-content-center">
                <button
                  type="button"
                  className="btn approved-button"
                  data-bs-dismiss="modal"
                >
                  Approved
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Popup;
