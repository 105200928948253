import React from "react";
import "../HeaderBilling/HeaderBilling.css";
import { Link, useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";
import Ellipse5 from "../image/Ellipse 5.png";
import bellimg from "../image/Group 48.png";
import barimg from "../image/Group 47.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useContext } from "react";
import { Context } from "../../../../utils/context";
import Cookies from "js-cookie";
import { useTranslation } from "react-i18next";

const HeaderGlobal = () => {
  const location = useLocation();
  const hideHeaderOnPaths = ["/"];
  const shouldHideHeader = hideHeaderOnPaths.includes(location.pathname);

  const navigate = useNavigate();
  const [t, i18n] = useTranslation("global");
  const { setUserData, setUsertype, setSignin } = useContext(Context);

  if (shouldHideHeader) {
    return null;
  }

  const LogOut = async () => {
    Cookies.remove("billing_security", { path: "/" });
    await setUserData("");
    await setUsertype("");
    await setSignin(false);
    navigate("/");
  };

  const Handlelangugae = async (e) => {
    i18n.changeLanguage(e.target.value);
    // console.log(i18n.language);
  };

  return (
    <>
      <section className="header">
        <div className="container-fluid p-1">
          <div className="row ms-0 me-0">
            <nav className="navbar navbar-expand-lg justify-content-between p-0">
              <div className="logo ms-md-5 ms-3">
                <Link to="/"></Link>{" "}
                <h2>
                  <span>Data</span> Entry Operator{" "}
                </h2>
              </div>
              <div className=" " id="navbarNav">
                <ul className="navbar-nav ">
                  <li className="nav-item">
                    <Link
                      to="/"
                      className="nav-link active me-md-2 me-2"
                      aria-current="page"
                    >
                      {" "}
                      <img src={bellimg} className="bell-img" alt="..." />
                    </Link>
                  </li>
                  <li className="nav-item">
                    <Link to="/" className="nav-link me-2 p-0">
                      <button className="btn-user" type="button">
                        <img src={Ellipse5} className="user-img" alt="..." />
                        <p className="shubham">Shubham</p>
                      </button>
                    </Link>
                  </li>
                  <li className="nav-item me-2">
                    <select
                      className="form-select language"
                      aria-label="Default select example"
                      onChange={Handlelangugae}
                    >
                      <option selected>Language</option>
                      <option value="1">Marathi</option>
                      <option value="2">English</option>
                    </select>
                  </li>
                  <li className="nav-item me-2">
                    {/* <Link className="nav-link me-0 p-0"> */}
                    <button
                      className="btn-logout"
                      onClick={LogOut}
                      type="button"
                    >
                      Log Out
                    </button>
                    {/* </Link> */}
                  </li>

                  <li className="nav-item">
                    <div className="button-more me-md-4 me-0 ">
                      <button className="btn btn-more" type="button">
                        <img src={barimg} className="bar-img" alt="..." />
                      </button>
                    </div>
                    <div className="button-more-mobile-main me-md-5 me-0">
                      <button
                        className="button-more-mobile"
                        type="button"
                        data-bs-toggle="offcanvas"
                        data-bs-target="#offcanvasRight"
                        aria-controls="offcanvasRight"
                      >
                        <FontAwesomeIcon icon="fa-solid fa-bars" />
                      </button>
                    </div>
                  </li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </section>
      {/* Mobile view Offcanvas */}
      <div className="header-offcanvas-class">
        <div
          className="offcanvas offcanvas-end "
          tabindex="-1"
          id="offcanvasRight"
          aria-labelledby="offcanvasRightLabel"
        >
          <div className="offcanvas-body">
            <ul
              className="nav nav-pills flex-column"
              id="pills-tab"
              role="tablist"
            >
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active "
                    id="pills-Generated-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Generated "
                    type="button"
                    role="tab"
                    aria-controls="pills-Generated"
                    aria-selected="true"
                  >
                    <p className="total">
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-solid fa-file"
                      />{" "}
                      Total Generated Bill
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="pills-Notice-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Notice"
                    type="button"
                    role="tab"
                    aria-controls="pills-Notice"
                    aria-selected="false"
                  >
                    <p className="total">
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-book"
                      />{" "}
                      Total Notice Generated
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item " role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Tax-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Tax"
                    type="button"
                    role="tab"
                    aria-controls="pills-Tax"
                    aria-selected="false"
                  >
                    <p className="total">
                      {" "}
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-file-invoice"
                      />{" "}
                      Total Tax Collection
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Ward-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Ward"
                    type="button"
                    role="tab"
                    aria-controls="pills-Ward"
                    aria-selected="false"
                  >
                    <p className="total">
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-map-location-dot"
                      />{" "}
                      Total ward Covered
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="pills-Arrears-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Arrears"
                    type="button"
                    role="tab"
                    aria-controls="pills-Arrears"
                    aria-selected="false"
                  >
                    <p className="total">
                      {" "}
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-hand-holding-hand"
                      />{" "}
                      Total Outstanding amount
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link "
                    id="pills-Recent-tab  "
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Recent"
                    type="button"
                    role="tab"
                    aria-controls="pills-Recent"
                    aria-selected="false"
                  >
                    <p className="total">
                      {" "}
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-file-invoice"
                      />{" "}
                      Recent Activity
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12"
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link  "
                    id="pills-Invoices-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Invoices"
                    type="button"
                    role="tab"
                    aria-controls="pills-Invoices"
                    aria-selected="false"
                  >
                    <p className="total">
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-file-invoice-dollar"
                      />{" "}
                      Total Invoices Generated Count Per Month/Day
                    </p>
                  </button>
                </li>
              </div>
              <div
                className="col-sm-12 col-12 "
                data-bs-toggle="offcanvas"
                data-bs-target="#offcanvasRight"
                aria-controls="offcanvasRight"
              >
                <li className=" nav-item-create " role="presentation">
                  <button
                    className="nav-link "
                    id="pills-Create-tab"
                    data-bs-toggle="pill"
                    data-bs-target="#pills-Create"
                    type="button"
                    role="tab"
                    aria-controls="pills-Create"
                    aria-selected="false"
                  >
                    <p className="total">
                      <FontAwesomeIcon
                        className="me-3 font-icon-class"
                        icon="fa-some-5lid fa-pen-to-square"
                      />{" "}
                      Create Bill
                    </p>
                  </button>
                </li>
              </div>
            </ul>
          </div>
        </div>
      </div>
    </>
  );
};

export default HeaderGlobal;
