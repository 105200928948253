import React from "react";
import DashboardContent from "./DashboardContent/DashboardContent";
// import Heading from '../Header/Heading/Heading'

const DashBoard = () => {
  return (
    <div>
      <DashboardContent />
    </div>
  );
};

export default DashBoard;
