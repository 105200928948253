import React from 'react'
import Countersidebar from '../counter-sidebar/Countersidebar';
import Table from '../counterwise/Table';
const Counterwise = () => {
    return (
        <>
            <section className='main-section main-sec-bg'>
                <div className='container-fluid'>
                    <div className='row'>
                        <Countersidebar />
                        <div className=' col-xl-9  col-lg-8  col-md-12'>
                            <div className='row'>
                                <Table />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Counterwise