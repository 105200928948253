import React from "react";
import { Link } from "react-router-dom";
import { useState } from "react";
import "./Tax.css";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import Sidebar from "../../Master/layout/Sidebar";

const Add = () => {
  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <div className="row">
                        <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                          {/* <div className="Add-content"> */}
                          <div className="row ">
                            <div className="col-md-12">
                              <div className="main-form-section mt-5 ">
                                <div className="row justify-content-center ">
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Tax Name{" "}
                                  </label>
                                  <div className="col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputEmail3"
                                    />
                                  </div>
                                </div>
                                <div className="row mt-2 justify-content-center">
                                  <label
                                    htmlFor="inputEmail3"
                                    className="col-sm-3 col-form-label"
                                  >
                                    Tax Rate
                                  </label>
                                  <div className="col-sm-6">
                                    <input
                                      type="text"
                                      className="form-control"
                                      id="inputEmail3"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                            {/* <div className="col-md-3"></div> */}
                          </div>
                          <div className="radio-main">
                            <div className="row  mt-3">
                              <div className="col-md-6  ">
                                <div className="d-flex">
                                  <p className="rate">Include in Rate</p>

                                  {/* <div className="col-xxl-3 col-md-6 col-sm-4 col-6  "> */}
                                  <div className="form-check form-check-inline ms-xxl-3">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      value="option1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      Yes
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio2"
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio2"
                                    >
                                      No
                                    </label>
                                  </div>
                                </div>
                                {/* </div> */}
                              </div>
                              <div className="col-md-6">
                                <div className="d-flex">
                                  <div className="status me-3">
                                    <p className="rate">status</p>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio1"
                                      value="option1"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio1"
                                    >
                                      Active
                                    </label>
                                  </div>
                                  <div className="form-check form-check-inline">
                                    <input
                                      className="form-check-input"
                                      type="radio"
                                      name="inlineRadioOptions"
                                      id="inlineRadio2"
                                      value="option2"
                                    />
                                    <label
                                      className="form-check-label"
                                      htmlFor="inlineRadio2"
                                    >
                                      InActive
                                    </label>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row  mt-5 pb-3">
                            <div className="d-flex justify-content-center">
                              <button
                                type="button"
                                className="btn btn-cancel me-2"
                              >
                                {" "}
                                <img
                                  src={cancel}
                                  className="cancel-img"
                                  alt=""
                                />{" "}
                                Cancel
                              </button>
                              <button
                                type="button"
                                className="btn btn-save"
                                data-bs-toggle="modal"
                                data-bs-target="#exampleModal1"
                              >
                                {" "}
                                <img
                                  src={save}
                                  className="save-img me-2"
                                  alt=""
                                />
                                Save
                              </button>
                            </div>
                          </div>
                          {/* </div> */}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className="modal fade"
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Tax Added Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
