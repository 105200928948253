import React from "react";
import "../Form.css";
import saveimg from "../../../StepForm/image/awesome-save.png";
import preimg from "../../../StepForm/image/Icon awesome-backward.png";

const construction = () => {
  return (
    <>
      <section className="Property-Form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4  col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Pro No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>ward 2</option>
                <option>ward 3</option>
                <option>ward 4</option>
                <option>ward 5</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Zone
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Zone 1</option>
                <option>Zone 2</option>
                <option>Zone 3</option>
                <option>Zone 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Block 1</option>
                <option>Block 2</option>
                <option>Block 3</option>
                <option>Block 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Route 1</option>
                <option>Route 2</option>
                <option>Route 3</option>
                <option>Route 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Autho. Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Authorized</option>
                <option>Unauthorised</option>
                <option>3</option>
                <option>4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>New Property</option>
                <option>Old Property</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Authorized?
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Legal
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* construction */}
          <div>
            <div className="mt-3">
              <h2>Construction Information</h2>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Start Date
                </label>
                <input
                  type="date"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  End Date
                </label>
                <input
                  type="date"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  License no.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Property Age.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
            <div className="line-bottom-class mt-3"></div>
          </div>
          {/* Land Information */}
          <div>
            <div className="mt-3">
              <h2>Land Information</h2>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Land Type
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Land Use Type
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                </select>
              </div>
            </div>
            <div className="line-bottom-class mt-3"></div>
            {/* License Certificate Information */}
            <div>
              <div className="row">
                <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 mt-3">
                  <h2>License Certificate Information</h2>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                  <label htmlFor="inputPassword5" className="form-label">
                    Certificate No.
                  </label>
                  <input
                    type="number"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                  <label htmlFor="inputPassword5" className="form-label">
                    Cert. Completion date.
                  </label>
                  <input
                    type="date"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                  <label htmlFor="inputPassword5" className="form-label">
                    Cert. Receive Date
                  </label>
                  <input
                    type="date"
                    id="inputPassword5"
                    className="form-control"
                    aria-describedby="passwordHelpBlock"
                  />
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                  <label htmlFor="inputPassword5" className="form-label">
                    Agency Name
                  </label>
                  <select id="inputState" className="form-select">
                    <option selected>--Select--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
                <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6 p-1">
                  <label htmlFor="inputPassword5" className="form-label">
                    License Type
                  </label>
                  <select id="inputState" className="form-select">
                    <option selected>--Select--</option>
                    <option>1</option>
                    <option>2</option>
                    <option>3</option>
                    <option>4</option>
                  </select>
                </div>
              </div>
            </div>
            <div className="text-center ">
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded me-2"
              >
                {" "}
                <img src={preimg} className="saveimg" /> Back
              </button>
              <button
                type="button"
                className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded"
              >
                {" "}
                <img src={saveimg} className="saveimg " /> Save
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default construction;
