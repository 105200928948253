import React from "react";
import "../Form.css";
import saveimg from "../../../StepForm/image/awesome-save.png";

const PropertyForm = () => {
  return (
    <>
      <section className="Property-Form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4  col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1 mt-3">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Pro No.
              </label>
              <input
                type="password"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="111100004"
              />
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>ward 2</option>
                <option>ward 3</option>
                <option>ward 4</option>
                <option>ward 5</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Zone
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Zone 1</option>
                <option>Zone 2</option>
                <option>Zone 3</option>
                <option>Zone 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Block 1</option>
                <option>Block 2</option>
                <option>Block 3</option>
                <option>Block 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Route 1</option>
                <option>Route 2</option>
                <option>Route 3</option>
                <option>Route 4</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Autho. Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>Authorized</option>
                <option>Unauthorised</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property Status
              </label>
              <select id="inputState" className="form-select">
                <option selected>--Select--</option>
                <option>New Property</option>
                <option>Old Property</option>
              </select>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Authorized?
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
            </div>
            <div className="col-xxl-3 col-xl-3 col-lg-3  col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Legal
              </label>
              <div className="col-3">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder=""
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Property owner details */}
          <div>
            <div className="mt-3">
              <h2>Property Owner Details</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Owner Name
                </label>
                <div className="row">
                  <div className="col-xxl-2 col-xl-2 col-lg-2 col-md-2 col-sm-2 col-2 me-1">
                    <div className="dropdown">
                      <button
                        className="btn dropdown-btn-class dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      ></button>
                      <ul className="dropdown-menu">
                        <li>
                          <a className="dropdown-item" href="#"></a>Mr.
                        </li>
                        <li>
                          <a className="dropdown-item" href="#"></a>Mrs.
                        </li>
                        <li>
                          <a className="dropdown-item" href="#"></a>Miss.
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className="col-xxl-10 col-xl-10 col-lg-10 col-md-10 col-sm-9 col-9">
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                    <div id="passwordHelpBlock" className="form-text">
                      <p className="text-center">Last Name</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-2 ">
                <label htmlFor="inputPassword5" className="form-label"></label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
                <div id="passwordHelpBlock" className="form-text">
                  <p className="text-center">First Name</p>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1 mt-2">
                <label htmlFor="inputPassword5" className="form-label"></label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
                <div id="passwordHelpBlock" className="form-text">
                  <p className="text-center">Middle Name</p>
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Org. Name
                </label>
                <input
                  type="password"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Contact No.
                </label>
                <input
                  type="password"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="+91-9096486896"
                />
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Pan No.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="APCPY3215E"
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Email Id.
                </label>
                <input
                  type="email"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Shubhamkumar019@gmail.com"
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Property Address */}
          <div>
            <div className="mt-3">
              <h2>Property Address</h2>
            </div>
            <div className="row">
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Plot No.
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  House No.
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Street Name
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Building Name
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 col-sm-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  City/ Town / Village
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Location
                </label>
                <select id="inputState" className="form-select">
                  <option selected>--Select--</option>
                  <option>Block 1</option>
                  <option>Block 2</option>
                  <option>Block 3</option>
                  <option>Block 4</option>
                </select>
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Country
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Districts
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-4 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Taluka
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                />
              </div>
              <div className="line-bottom-class mt-3"></div>
              {/* property Details */}
              <div>
                <div className="mt-3">
                  <h2>property Details</h2>
                </div>
                <div className="property-details">
                  <div className="row mt-3 ">
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <div className="text-holder-form">
                        <p>Property Type</p>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <select id="inputState" className="form-select">
                        <option selected>--Select--</option>
                        <option>Block 1</option>
                        <option>Block 2</option>
                        <option>Block 3</option>
                        <option>Block 4</option>
                      </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="text-holder-form">
                        <p>Property Sub-Type</p>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <select id="inputState" className="form-select">
                        <option selected>--Select--</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <div className="text-holder-form">
                        <p>Usage Type</p>
                      </div>
                    </div>
                    <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6">
                      <select id="inputState" className="form-select">
                        <option selected>--Select--</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-6 col-6">
                      <div className="text-holder-form">
                        <p>Subtype</p>
                      </div>
                    </div>
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6 col-sm-6 col-6">
                      <select id="inputState" className="form-select">
                        <option selected>--Select--</option>
                        <option>1</option>
                        <option>2</option>
                        <option>3</option>
                        <option>4</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
              <div className="line-bottom-class mt-3"></div>
              {/* Assessment Date Details */}
              <div>
                <div className="mt-3">
                  <h2>Assessment Date Details</h2>
                </div>
                <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Current Assess. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Order Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      First Ass. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Primary Ass. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Last Ass. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Revised Ass. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Updated Ass. Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6 col-sm-6 col-6 p-1">
                    <label htmlFor="inputPassword5" className="form-label">
                      Construction End Date
                    </label>
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                    />
                  </div>
                  <div className="line-bottom-class mt-3 "></div>
                  {/* Building Structure */}
                  <div>
                    <div className="mt-3">
                      <h2>Building Structure</h2>
                    </div>
                    <div className="row">
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Building Structure
                        </label>
                        <select id="inputState" className="form-select">
                          <option selected>--Select--</option>
                          <option>1</option>
                          <option>2</option>
                          <option>3</option>
                          <option>4</option>
                        </select>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Floors
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                        />
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Total Floors
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                        />
                      </div>
                      <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                        <label htmlFor="inputPassword5" className="form-label">
                          Remarks
                        </label>
                        <input
                          type="text"
                          id="inputPassword5"
                          className="form-control"
                          aria-describedby="passwordHelpBlock"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="text-center">
                <button
                  type="button"
                  className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded"
                >
                  {" "}
                  <img src={saveimg} className="saveimg " /> Save
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PropertyForm;
