import React from "react";
import { Bar } from 'react-chartjs-2';
import download from '../../StepForm/image/download.png'
import exelsheet from '../../StepForm/image/excel.png'
import pdf from '../../StepForm/image/pdf.png'

const TwoDGraph = () => {
  const data = {
    labels: ['Jan', 'Feb', 'Mar', 'April', 'May', 'Jun', 'July', 'Aug', 'Sep', 'Nov', 'Dec'],
    datasets: [
      {
        label: 'Sales',
        data: [35, 19, 15, 15, 20, 23, 30, 25, 43, 35, 25, 15,],
        backgroundColor: 'rgba(75,192,192,0.6)',
      },
      {
        label: 'Revenue',
        data: [25, 10, 24, 30, 10, 9, 15, 25, 20, 25, 10],
        backgroundColor: 'rgba(255,99,132,0.6)',
      },
    ],
  };
  const options = {
    scales: {
      y: {
        beginAtZero: true,
      },
    },
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 ps-md-0 mt-4">
            <div className="main-total mt-5">
              <p className="text-center p-3 ">Tax Collection</p>
            </div>
            <div className="excel-pdf-tab">
              <div className="main-total2">
                <p className="text-center p-3"><img src={exelsheet} className="excelimg me-5 me-md-3" alt="..." />Excelsheet <img src={download} className="excelimg ms-5 ms-md-2" alt="..." /></p>
              </div>
              <div className="main-total2">
                <p className="text-center p-3"><img src={pdf} className="excelimg me-5 me-md-3" alt="..." />PDF File <img src={download} className="excelimg ms-5 ms-md-2" alt="..." /></p>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5 p-0">
            <div className="graph p-3 mb-5">
              <div>
                <Bar data={data} options={options} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TwoDGraph;
