import React, { useContext, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useState } from "react";
import "../Tax.css";
import circle from "../../assets/icons/circle.png";
import rigth from "../../assets/icons/rigth.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import Sidebar from "../layout/Sidebar";
import { Context } from "../../../../utils/context";

const Add = () => {
  const { getEditData, editData, getData } = useContext(Context);
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState();
  const [roles, setRoles] = useState();

  const [formData, setFormData] = useState({
    name: "",
    email: "",
    phone: "",
    password: "",
    role: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regex = /^[A-Za-z]+(\s[A-Za-z]+)*$/;

    if (!formData.name.trim()) {
      errors.name = "User Name is required";
    } else if (!regex.test(formData.name)) {
      errors.name = "Enter Valid User Name";
    }

    const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

    if (!formData.email.trim()) {
      errors.email = "Email is required";
    } else if (!emailRegex.test(formData.email)) {
      errors.email = "Enter a valid email address";
    }

    // Regular expression for a 10-digit phone number
    const phoneRegex = /^\d{10}$/;

    if (!formData.phone.trim()) {
      errors.phone = "Phone number is required";
    } else if (!phoneRegex.test(formData.phone)) {
      errors.phone = "Enter a valid 10-digit phone number";
    }

    if (!formData.password.trim()) {
      errors.password = "Password is required";
    }

    if (!formData.role.trim()) {
      errors.role = "Please Select Role";
    }

    return errors;
  };


  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
    } else {
      try {
        const response = await editData("/usersmanagement/editusers", formData);

        if (response.success) {
          setShowModal(true);
          setTimeout(() => {
            setShowModal(false);
            navigate("/usermanagement");
          }, 1000);
        } else {
          setShowErrorModal(true);
          setTimeout(() => {
            setShowErrorModal(false);
            navigate(`/usermanagement/edituser/${id}`);
          }, 1000);
        }
      } catch (error) {
        console.error("Error submitting form:", error);
      }
    }
  };

  const GetEditData = async () => {
    const response = await getEditData(`/usersmanagement/geteditusers/${id}`);
    const response2 = await getData(`/rolemanagement/getallroles`);
    setRoles(response2?.data);
    setData(response);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />
              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <div className="Back  me-4">
                        <Link to="/tax-master/agencyname">
                          <button type="button" className="btn btn-back">
                            <img src={rigth} className="rigth" alt="" /> Back{" "}
                          </button>
                        </Link>
                      </div>
                      <div className="border-line1 mt-3"></div>
                      <form onSubmit={handleSubmit} role="form">
                        <div className="row">
                          <div className="col-lg-8 col-md-10 mt-5 mx-auto Add-content">
                            <div className="row ">
                              <div className="col-md-12">
                                <div className="main-form-section mt-5 ">
                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Name{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="name"
                                        value={formData?.name}
                                        onChange={handleChange}
                                        placeholder="Name"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.name && (
                                        <span style={errorStyle}>
                                          {errors.name}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Email{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="email"
                                        name="email"
                                        value={formData?.email}
                                        onChange={handleChange}
                                        placeholder="Email"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.email && (
                                        <span style={errorStyle}>
                                          {errors.email}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center mb-2">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Phone{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="tel"
                                        name="phone"
                                        value={formData?.phone}
                                        onChange={handleChange}
                                        placeholder="phone"
                                        className="form-control"
                                        id="inputphone3"
                                      />
                                      {errors.phone && (
                                        <span style={errorStyle}>
                                          {errors.phone}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center ">
                                    <label
                                      htmlFor="inputEmail3"
                                      className="col-sm-3 col-form-label"
                                    >
                                      Password{" "}
                                    </label>
                                    <div className="col-sm-6">
                                      <input
                                        type="text"
                                        name="password"
                                        value={formData?.password}
                                        onChange={handleChange}
                                        placeholder="Password"
                                        className="form-control"
                                        id="inputEmail3"
                                      />
                                      {errors.password && (
                                        <span style={errorStyle}>
                                          {errors.password}
                                        </span>
                                      )}
                                    </div>
                                  </div>

                                  <div className="row justify-content-center">
                                    <label
                                      htmlFor="inputPhone"
                                      className="col-sm-3 col-form-label"
                                    >
                                      User Role
                                    </label>
                                    <div className="col-sm-6">
                                      <select
                                        className="form-control"
                                        onChange={handleChange}
                                        name="role"
                                      >
                                        {/* Mapping over the 'roles' array to create options */}
                                        {roles?.map((d) => {
                                          if (formData?.role == d?.id) {
                                            return (
                                              <option key={d?.id} value={d?.id}>
                                                {d?.name}
                                              </option>
                                            );
                                          } else {
                                            return null; // Return an empty fragment if no match
                                          }
                                        })}
                                        {/* Mapping over the 'roles' array to create options */}
                                        {roles?.map((d) => {
                                          if (formData?.role == d?.id) {
                                            return null;
                                          } else {
                                            return (
                                              <option key={d?.id} value={d?.id}>
                                                {d?.name}
                                              </option>
                                            );
                                            // Return an empty fragment if no match
                                          }
                                        })}
                                      </select>
                                      {errors.role && (
                                        <span style={errorStyle}>
                                          {errors.role}
                                        </span>
                                      )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="row  mt-5 pb-3">
                              <div className="d-flex justify-content-center">
                                <Link to="/usermanagement">
                                  <button
                                    type="button"
                                    className="btn btn-cancel me-2"
                                  >
                                    {" "}
                                    <img
                                      src={cancel}
                                      className="cancel-img"
                                      alt=""
                                    />{" "}
                                    Cancel
                                  </button>
                                </Link>
                                <button
                                  type="submit"
                                  onClick={handleSubmit}
                                  className="btn btn-save"
                                >
                                  {" "}
                                  <img
                                    src={save}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                            {/* </div> */}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  User Name Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  User Name Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Add;
