import React from "react";
import Table from "../user-details/Table";
import BasicSidebar from "../basic-setting/BasicSidebar";
const UserDetails = () => {
  return (
    <>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <BasicSidebar />
            <div className=" col-xl-9  col-lg-8  col-md-12">
              <div className="row">
                <Table />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default UserDetails;
