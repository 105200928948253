import React, { useContext, useEffect, useState } from "react";
import "../BillView/Billview.css";
import saveimg from "../../StepForm/image/awesome-save.png";
import preimg from "../../StepForm/image/Icon awesome-backward.png";
import { useParams } from "react-router-dom";
import { Context } from "../../../../utils/context";
const BillView = () => {
  const { id } = useParams();
  const { getData } = useContext(Context);

  const [data, setData] = useState([]);

  useEffect(() => {
    const GetdetailData = async () => {
      const response = await getData(
        `/createbill/propertyinfo/getallpropertydetailsbyid/${id}`
      );
      await setData(response.data);
    };
    GetdetailData();
  }, []);
  return (
    <>
      <div className="Property-Form">
        <div className="container">
          <div className="row">
            <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mt-5">
              <div className="title-form mt-5">
                <h3>{data?.first_name} Property Bill</h3>
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                // placeholder="111100004"
                value={data?.property_no}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Sequence No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.sequence_no}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Old Prop. No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.old_property_no}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ref Property No.
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                value={data?.ref_property_no}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Ward
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder={data?.ward_id}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Zone
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder="Zone 1"
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Block
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder={data?.block_id}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Route
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder={data?.route_id}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Autho. Status
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder={data?.auth_status}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Property Status
              </label>
              <input
                type="text"
                id="inputPassword5"
                className="form-control"
                aria-describedby="passwordHelpBlock"
                placeholder={data?.property_status}
              />
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Authorized?
              </label>
              <div className="col-3">
                <input
                  type="checkbox"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.authorised}
                />
              </div>
            </div>
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
              <label htmlFor="inputPassword5" className="form-label">
                Legal
              </label>
              <div className="col-3">
                <input
                  type="checkbox"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.legal}
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          <div>
            <div className="mt-3">
              <h2>Property Owner Details</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-3 col-sm-1 col-1 me-1">
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                      placeholder={data?.initial}
                    />
                  </div>
                  <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-9 col-sm-8 col-8">
                    <input
                      type="text"
                      id="inputPassword5"
                      className="form-control"
                      aria-describedby="passwordHelpBlock"
                      placeholder={data?.lastname}
                    />
                    <div
                      id="passwordHelpBlock"
                      className="form-text text-center"
                    >
                      Last Name
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <input
                  type="text"
                  // id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.first_name}
                />
                <div id="passwordHelpBlock" className="form-text text-center">
                  First Name
                </div>
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <input
                  type="text"
                  //id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.mid_name}
                />
                <div id="passwordHelpBlock" className="form-text text-center">
                  Middle Name
                </div>
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Org. Name
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.org_name}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Contact No.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.contact_number}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Pan No.
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.pan_number}
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Email Id.
                </label>
                <input
                  type="emailk"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.email}
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          <div>
            <div className="mt-3">
              <h2>Property Address</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Plot No.
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.plot_no}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  House No.?
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.house_no}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Street Name?
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.street_name}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Area
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="ShivShakti"
                />
              </div>
              <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Building Name
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.building_name}
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Address
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.address}
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  City/ Town / Village
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.cityname}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Location
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.location}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Country
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  pplaceholder={data?.countryname}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  State
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.statename}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Districts
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.districtname}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Taluka
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="taluka"
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Property Details */}
          <div>
            <div className="mt-3">
              <h2>Property Address</h2>
            </div>
            <div className="row shadow-sm p-3 mb-5 bg-body rounded text-end">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1 mt-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Property Type
                </label>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.property_type}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1 mt-1 ">
                <label htmlFor="inputPassword5" className="form-label">
                  Property Sub-Type
                </label>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.property_sub_type}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1 mt-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Usage Type
                </label>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="Mixed"
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1 mt-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Subtype
                </label>
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder="A"
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Assessment Date Details */}
          <div className=" mt-3">
            <div>
              <h2>Assessment Date Details</h2>
            </div>
            <div className="row">
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Current Assess. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.primary_asses_date}
                  // placeholder={
                  //   data?.primary_asses_date
                  //               ? new Date(data.primary_asses_date)
                  //                   .toISOString()
                  //                   .split("T")[0]
                  //               : ""
                  //           }
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Order Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.order_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  First Ass. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.first_asses_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Primary Ass. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.primary_asses_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Last Ass. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.last_asses_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Revised Ass. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.revised_asses_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Updated Ass. Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.updated_asses_date}
                />
              </div>
              <div className="col-xxl-6 col-xl-6 col-lg-6 col-md-6 col-sm-6 col-6 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Construction End Date
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.construction_end_date}
                />
              </div>
            </div>
          </div>
          <div className="line-bottom-class mt-3"></div>
          {/* Building Structure */}
          <div>
            <div className="mt-3">
              <h2>Building Structure</h2>
            </div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Building Structure
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.billingstructure}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Floors
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.floors}
                />
              </div>
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-4 col-4 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Total Floors
                </label>
                <input
                  type="number"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.total_floors}
                />
              </div>
              <div className="col-xxl-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12 p-1">
                <label htmlFor="inputPassword5" className="form-label">
                  Remarks
                </label>
                <input
                  type="text"
                  id="inputPassword5"
                  className="form-control"
                  aria-describedby="passwordHelpBlock"
                  placeholder={data?.remarks}
                />
              </div>
            </div>
          </div>
          <div className="text-center ">
            <button
              type="button"
              className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded me-2"
            >
              {" "}
              <img src={preimg} className="saveimg" /> Back
            </button>
            <button
              type="button"
              className="btn savebtn shadow-lg mb-4 mt-4 bg-body rounded"
            >
              {" "}
              <img src={saveimg} className="saveimg " /> Save
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default BillView;
