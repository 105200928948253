import axiosClient from "./ApiInstance";
import Cookies from "js-cookie";
import axios from "axios";

export const getData = async (url, data) => {
  try {
    const response = await axiosClient.get(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const postData = async (url, data) => {
  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const getEditData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const editData = async (url, data) => {
  try {
    const response = await axiosClient.post(url, data);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const editStatusData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const deleteData = async (url) => {
  try {
    const response = await axiosClient.get(url);
    return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

// Download Apis
export const getDownloadDataExcel = async (url, data) => {
  try {
    const response = await axiosClient.get(url);

    // Handle successful response
    // const blob = new Blob([response.data], { type: "application/pdf" });
    const blob = new Blob([response.data], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "downloaded-pdf.pdf";
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);

    // return response.data;
  } catch (error) {
    Cookies.remove("billing_security", { path: "/" });
    window.location.href = "/";
    console.log(error);
  }
};

export const getDownloadDataPDF = async (aurl, name) => {
  try {
    const token = Cookies.get("billing_security");
    // Make a request to download the PDF with the headers
    const headers = {
      Authorization: `Bearer ${token}`,
    };
    const downloadUrl = process.env.REACT_APP_BASE_URL + aurl;
    const response = await axios.get(downloadUrl, {
      responseType: "arraybuffer",
      headers,
    });

    // Convert the response data to a Blob
    const blob = new Blob([response.data], { type: "application/pdf" });

    // Create a download link
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = `${name}.pdf`; // Adjust the filename as needed
    document.body.appendChild(a);

    // Trigger the download
    a.click();

    // Clean up
    window.URL.revokeObjectURL(url);
  } catch (error) {
    // Handle errors here
    console.error("Failed to download PDF. Error: ", error);
  }
};

export const getDownloadPdf = async (aurl, data, name) => {
  try {
    // Assuming you have a server-side endpoint that generates a PDF and returns it as a blob
    const response = await axiosClient.get(
      process.env.REACT_APP_BASE_URL + aurl,
      {
        responseType: "blob", // Set responseType to "blob" for binary data
      }
    );

    const url = window.URL.createObjectURL(new Blob([response.data]));
    // Create a temporary anchor element to trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.setAttribute(
      "download",
      `${name + " " + new Date().toLocaleDateString()}.pdf`
    );
    document.body.appendChild(link);
    link.click();

    // Clean up after the download
    window.URL.revokeObjectURL(url);
  } catch (error) {
    console.log(error);
  }
};
