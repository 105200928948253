import React, { useContext, useEffect } from "react";
import { useState } from "react";
import "../Tabels/Tabels.css";
import { Link } from "react-router-dom";
import arrow from "../../assets/icons/arrow.png";
import plus from "../../assets/icons/PLUS.png";
import colunms from "../../assets/icons/LINES.png";
import pen from "../../assets/icons/pen.png";
import basket from "../../assets/icons/basket.png";
import search from "../../assets/icons/search.png";
import top from "../../assets/icons/top.png";
import blackeye from "../../assets/icons/blackeye.png";
import search1 from "../../assets/icons/search.png";
import whiteimg from "../../assets/icons/eye.png";
import axios from "axios";
import Add from "./Add";

import { Context } from "../../../../utils/context";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import toast, { Toaster } from "react-hot-toast";

const Table = () => {
  // const [data,setData]=useState([])
  // const {getCustomerMaster,deleateCustomerMaster} = useContext(Context);
  const { getData, editStatusData, deleteData } = useContext(Context);
  const [data, setData] = useState([]);
  const [changeStatus, setChangeStatus] = useState();
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setperPage] = useState(5);
  const [search, setSearch] = useState("");
  const [reset, setReset] = useState();
  const [no, setNo] = useState(0);

  const ErrorNotify = () => {
    toast.error("customer Master name deleted successfully.", {
      position: "top-right",
      autoClose: 100,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      theme: "dark",
      style: { background: "red", color: "white" },
    });
  };

  const handleGetall = async () => {
    const response = await getData(
      // '/customermaster/customermaster/customermaster/getcustomermaster'
      `/customermaster/customermaster/customermaster/getcustomermaster?page=${currentPage}&per_page=${perPage}&term=${search}`
    );


    await setData(response);
    setCurrentPage(response?.data?.currentPage);
    setperPage(response?.data?.per_page);
    setSearch(response?.data?.search_name);
  };

  useEffect(() => {
    handleGetall();
  }, [changeStatus, perPage, reset]);

  const ChangeStatus = async (id) => {
    const response = await editStatusData(
      `/customermaster/customermaster/customermaster/changestatus/${id}`
    );
    setChangeStatus(response);
  };

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [recordToDeleteId, setRecordToDeleteId] = useState(null);

  const showDeleteRecord = async (id) => {
    setShowDeleteModal(true);
    setRecordToDeleteId(id);
  };

  const DeleteRecord = async (id) => {
    setShowDeleteModal(false);
    ErrorNotify();
    if (recordToDeleteId) {
      const response = await deleteData(
        `/customermaster/customermaster/customermaster/deletedata/${recordToDeleteId}`
      );
      setChangeStatus(response);
      setRecordToDeleteId(null);
    }
  };

  const handleDeleteCancel = () => {
    setShowDeleteModal(false);
    setRecordToDeleteId(null);
  };

  //toggle................................
  const [visible, setVisibel] = useState({
    col0: true,
    col1: true,
    col2: true,
    col3: true,
    col4: true,
    col5: true,
    col6: true,
    col7: true,
    col8: true,
  });

  //toggle columnns.........
  const toggleColumn = (event, columnName) => {
    event.preventDefault();
    setVisibel((prev) => ({
      ...prev,
      [columnName]: !prev[columnName],
    }));
  };

  return (
    <>
      <div className="col-xxl-12 col-xl-12   ">
        {/* {isActive ?  
        <section className="Tabels tab-radio mt-4">

          <Add />
          
          </section>
        
           :  */}
        <section className="Tabels tab-radio tab-radio mt-5">
          <div className="Tabels tab-radio">
            <div className="container">
              <div className="row   mt-3">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb">
                    <li className="breadcrumb-item">
                      Tax Master <img src={arrow} className="image" alt="" />
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      Country
                    </li>
                  </ol>
                </nav>
              </div>
              <div className="row">
                <div className="d-flex">
                  <div className="add me-3">
                    <Link to="/customer-master/add">
                      <button type="button" className="btn btn-add pe-3">
                        {" "}
                        <img src={plus} className="plus me-2 ms-0" alt="" /> Add
                        Customer Master{" "}
                      </button>
                    </Link>
                  </div>
                  {/* </div>

              <div className="  col-xl-4  col-lg-6 col-md-6  col-sm-6 col-12"> */}
                  <div className="add">
                    <div className="dropdown">
                      <button
                        className="btn btn-columns dropdown-toggle"
                        type="button"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        <img src={colunms} className="columns " alt="" /> Column
                        Selection <img src={top} className="top ms-1" alt="" />
                      </button>
                      <ul className="dropdown-menu">
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col0")}
                            href="#"
                          >
                            Check Boxs
                            {visible?.col0 ? (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col1")}
                            href="#"
                          >
                            Sr. No.
                            {visible?.col1 ? (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col2")}
                            href="#"
                          >
                            Owner Name
                            {visible?.col2 ? (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col3")}
                            href="#"
                          >
                            Property Type
                            {visible?.col3 ? (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col4")}
                            href="#"
                          >
                            Area
                            {visible?.col4 ? (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col5")}
                            href="#"
                          >
                            Landmark
                            {visible?.col5 ? (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col6")}
                            href="#"
                          >
                            Address
                            {visible?.col6 ? (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-2 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col7")}
                            href="#"
                          >
                            Status
                            {visible?.col7 ? (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                        <li>
                          <Link
                            className="dropdown-item"
                            onClick={(event) => toggleColumn(event, "col8")}
                            href="#"
                          >
                            Action
                            {visible?.col8 ? (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye"
                              />
                            ) : (
                              <FontAwesomeIcon
                                className="ms-4 eye-icon-img"
                                icon="fa-solid fa-eye-slash"
                              />
                            )}
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  {/* <div className="view-details ">
                  <Link to="/customer-view">
                    {" "}
                    <button className="btn btn-view">
                      <img src={whiteimg} className="eye-img me-2" alt="" />
                      View Details
                    </button>
                  </Link>
                </div> */}
                </div>
              </div>
              <div className="border-line mt-3"></div>
              {/* <form>
              <div className="row District mt-4">
                <div className="col-md-4">
                  <div className="row mb-3">
                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                      District
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row mb-3">
                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                      Taluka
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
                <div className="col-md-4">
                  <div className="row mb-3">
                    <label htmlFor="inputEmail3" className="col-sm-3 col-form-label">
                      Pincode
                    </label>
                    <div className="col-sm-9">
                      <select
                        className="form-select"
                        aria-label="Default select example"
                      >
                        <option selected>All</option>
                        <option value="1">One</option>
                        <option value="2">Two</option>
                        <option value="3">Three</option>
                      </select>
                    </div>
                  </div>
                </div>
              </div>
            </form> */}

              <div className="row mt-3">
                <div className="data table-responsive">
                  <table className="table table-bordered  tableborder">
                    <thead>
                      <tr className="">
                        <th>
                          <div className="form-check">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              value=""
                              id="flexCheckDefault"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="flexCheckDefault"
                            ></label>
                          </div>
                        </th>
                        {visible.col1 && <th className="sr">Sr. No.</th>}
                        {visible.col2 && <th className="tax1">Owner Name</th>}
                        {visible.col3 && (
                          <th className="rate1">Property Type</th>
                        )}
                        {visible.col4 && <th className="sub1">Area</th>}
                        {visible.col5 && <th className="include1">Landmark</th>}
                        {visible.col6 && <th className="status1">Address</th>}
                        {visible.col7 && <th className="sub1">Status</th>}
                        {visible.col8 && <th className="active1">Action</th>}
                      </tr>
                    </thead>
                    <tbody>
                      {data?.data?.customermaster?.map((elem, index) => (
                        <tr className="" key={index}>
                          <td>
                            <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                value=""
                                id="flexCheckDefault"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="flexCheckDefault"
                              ></label>
                            </div>
                          </td>
                          {visible.col1 && <td>{++index}.</td>}
                          {visible.col2 && <td>{elem?.name}</td>}
                          {visible.col3 && <td>{elem.propertytypeid}</td>}
                          {visible.col4 && <td>{elem.area} Sq. Ft.</td>}
                          {visible.col5 && <td>{elem.landmark}</td>}
                          {visible.col6 && <td>{elem.address}</td>}
                          {visible.col7 && (
                            <td>
                              {elem?.status ? (
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    defaultChecked
                                    onChange={() => {
                                      ChangeStatus(elem?.id);
                                    }}
                                    id="flexSwitchCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                  >
                                    Active
                                  </label>
                                </div>
                              ) : (
                                <div className="form-check form-switch">
                                  <input
                                    className="form-check-input"
                                    type="checkbox"
                                    role="switch"
                                    onChange={() => {
                                      ChangeStatus(elem?.id);
                                    }}
                                    id="flexSwitchCheckDefault"
                                  />
                                  <label
                                    className="form-check-label"
                                    htmlFor="flexSwitchCheckDefault"
                                  >
                                    Inactive
                                  </label>
                                </div>
                              )}
                            </td>
                          )}
                          {visible.col8 && (
                            <td>
                              <div className="d-flex">
                                <div className="me-2">
                                  <Link
                                    to={`/customer-master/edit/${elem?.id}`}
                                    type="button"
                                    className="btn btn-primary me-1"
                                  >
                                    <img src={pen} className="pen" alt="" />
                                  </Link>
                                </div>
                                <div>
                                  <button
                                    onClick={() => {
                                      showDeleteRecord(elem?.id);
                                    }}
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    <img src={basket} className="pen" alt="" />
                                  </button>
                                </div>
                              </div>
                            </td>
                          )}
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="row">
                <div className="  col-lg-4 col-md-4 col-12">
                  <div className="d-flex">
                    <div className="show me-2">
                      <p className="show mt-2">Show</p>
                    </div>
                    <div className="number me-2">
                      <select
                        className="form-select form-select-sm"
                        aria-label=".form-select-sm example"
                        onChange={(e) => setperPage(e.target.value)}
                      >
                        <option value="5">5</option>
                        <option value="10">10</option>
                        <option value="15">15</option>
                        <option value="20">20</option>
                      </select>
                    </div>
                    <div className="entries">
                      <p className="show mt-2">entries</p>
                    </div>
                  </div>
                </div>
                <div className="  col-lg-8  col-md-8 col-12">
                  <div className="d-flex justify-content-end">
                    <div className="sowing me-2">
                      <p className="show mt-1">{`Showing ${Math.min(
                        (currentPage - 1) * perPage + 1
                      )} to ${Math.min(
                        currentPage * perPage,
                        data?.data?.totalEntries
                      )} of ${data?.data?.totalEntries} entries`}</p>
                    </div>
                    <div className="num me-2">
                      <input
                        type="text"
                        className="form-control"
                        id=""
                        value={search}
                        onChange={(e) => {
                          setSearch(e.target.value);
                        }}
                      />
                    </div>
                    <div className="Search me-2">
                      <button
                        type="button"
                        onClick={handleGetall}
                        className="btn btn-search"
                      >
                        <img src={search1} className="search" alt="" />
                      </button>
                    </div>

                    <div className="Search-1">
                      <button
                        type="button"
                        onClick={() => {
                          setSearch("");
                          setReset(!reset);
                        }}
                        className="btn btn-reset"
                      >
                        Reset
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>

        {/* } */}
      </div>

      <Toaster position="top-right" />

      {/* <!-- Modal Delete --> */}
      <div className="upload-modal">
        <div
          className={`modal fade ${showDeleteModal ? "show" : ""}`}
          style={{ display: showDeleteModal ? "block" : "none" }}
          id="exampleModaldel"
          tabIndex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <h1 className="add-success text-center  mt-2">
                  Are you sure ?
                </h1>
                <p>
                  Do You Really Want to Delete These Record?
                  <br /> This Process CanNot Be Undone{" "}
                </p>
                <div className="button-holder text-center mt-2">
                  <button className="btn btn-yes me-2" onClick={DeleteRecord}>
                    Yes
                  </button>
                  <button className="btn btn-no" onClick={handleDeleteCancel}>
                    No
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Table;
