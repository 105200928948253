import React, { useContext, useEffect, useState } from "react";
import { Bar } from "react-chartjs-2";
import { Canvas } from "react-three-fiber";
import "../generatetab-first/generatedtabfirst.css";
import { Chart as ChartJS, registerables } from "chart.js";
import exelsheet from "../../StepForm/image/excel.png";
import download from "../../StepForm/image/download.png";
import pdf from "../../StepForm/image/pdf.png";
import { Context } from "../../../../utils/context";

ChartJS.register(...registerables);
const GenerateTabFirst = () => {
  const [lable, setLable] = useState([]);
  const [totalCount, setTotalCount] = useState();
  const [datacount, setdatacount] = useState([]);
  const { getData } = useContext(Context);

  const getBarData = async () => {
    const respons = await getData("/dashboard/genereatebill/getgeneratebill");

    if (respons?.success) {
      const monthNames = respons?.data?.recordsCountByMonth?.map(
        (element) => element?.monthName
      );
      setLable(monthNames);
      const data = respons?.data?.recordsCountByMonth?.map(
        (element) => element?.recordCount
      );
      setdatacount(data);

      setTotalCount(respons?.data?.totalCount);
    }
  };

  useEffect(() => {
    getBarData();
  }, []);

  const data = {
    labels: lable,
    datasets: [
      {
        label: "Months",
        data: datacount,
        backgroundColor: [
          "#FEB475",
          "#FE7600",
          "#FEB475",
          "#FE7600",
          "#FEB475",
          "#FE7600",
          "#FEB475",
          "#FE7600",
          "#FEB475",
          "#FE7600",
          "#FEB475",
          "#FE7600",
        ],
      },
    ],
  };

  const ExcelhandleDownload = () => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/exceldownloadgeneratebill"; // Replace with the actual path
  };

  const PdfhandleDownload = () => {
    window.location.href =
      process.env.REACT_APP_BASE_URL +
      "/dashboard/genereatebill/pdfdownloadgeneratebill"; // Replace with the actual path
  };
  return (
    <>
      <div className="container">
        <div className="row">
          <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-12 text-center mt-md-0 mt-0 ps-md-0 mt-4">
            <div className="main-total mt-5">
              <p className="text-center p-3">
                Total Generated Bill - {totalCount}
              </p>
            </div>
            <div className="excel-pdf-tab ">
              <div className="main-total2" onClick={ExcelhandleDownload}>
                <p className="text-center p-3">
                  <img
                    src={exelsheet}
                    className="excelimg me-5 me-md-3"
                    alt="..."
                  />
                  Excelsheet{" "}
                  <img
                    src={download}
                    className="excelimg ms-5 ms-md-2"
                    alt="..."
                  />
                </p>
              </div>
              <div className="main-total2" onClick={PdfhandleDownload}>
                <p className="text-center p-3">
                  <img src={pdf} className="excelimg me-5 me-md-3" alt="..." />
                  PDF File{" "}
                  <img
                    src={download}
                    className="excelimg ms-5 ms-md-2"
                    alt="..."
                  />
                </p>
              </div>
            </div>
          </div>
          <div className="col-xxl-9 col-xl-9 col-lg-9 col-md-12 mt-5 p-0">
            <div className="graph p-3 mb-5">
              <div>
                <p className="text-end text-graph">Generated Bill</p>
                <Bar data={data} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default GenerateTabFirst;
