import { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import sub from "../../assets/icons/sub.png";
import add1 from "../../assets/icons/add1.png";
import group from "../../assets/icons/Group.png";
import document from "../../assets/icons/document.png";
import notice from "../../assets/icons/Notice.png";
import payment from "../../assets/icons/payement.png";
import fine from "../../assets/icons/fine.png";
import tax from "../../assets/icons/tax.png";
import layer from "../../assets/icons/Layer.png";
const BasicSidebar = () => {
  const location = useLocation();
  const [headerText, setHeaderText] = useState(location.pathname);

  useEffect(() => {
    setHeaderText(location.pathname);
  });
  return (
    <>
      <div className=" col-xl-3 col-lg-4 col-md-12 background-col p-0">
        <div
          className="tax-tabs nav mt-5 Menu-List nav-pills"
          id="v-pills-tab"
          role="tablist"
          aria-orientation="vertical"
        >
          <button
            className={
              headerText === "/company" ? "nav-link active" : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/company">
              <img src={add1} className="tab-image me-4" alt="" />
              Company Details
            </Link>
          </button>
          <button
            className={
              headerText === "/user-details"
                ? "nav-link active"
                : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/user-details">
              <img src={sub} className="tab-image me-4" alt="" />
              User Details
            </Link>
          </button>
          <button
            className={
              headerText === "/localization"
                ? "nav-link active"
                : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/localization">
              <img src={tax} className="tab-image me-4" alt="" />
              Localisation
            </Link>
          </button>
          <button
            className={
              headerText === "/online" ? "nav-link active" : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/online">
              <img src={group} className="tab-image me-4" alt="" />
              Online Payments
            </Link>
          </button>
          <button
            className={
              headerText === "/tax-rates" ? "nav-link active" : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/tax-rates">
              <img src={document} className="tab-image me-4" alt="" />
              Tax Rates
            </Link>
          </button>
          <button
            className={
              headerText === "/product" ? "nav-link active" : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/product">
              <img src={notice} className="tab-image me-4" alt="" />
              Products
            </Link>
          </button>
          <button
            className={
              headerText === "/notification"
                ? "nav-link active"
                : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/notification">
              <img src={payment} className="tab-image me-4" alt="" />
              Notifications
            </Link>
          </button>
          <button
            className={
              headerText === "/export" ? "nav-link active" : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/export">
              <img src={fine} className="tab-image me-4" alt="" />
              Import / Export
            </Link>
          </button>
          <button
            className={
              headerText === "/account-manager"
                ? "nav-link active"
                : "nav-link mb-2"
            }
          >
            <Link className="butt-decoration" to="/account-manager">
              <img src={layer} className="tab-image me-4" alt="" />
              Account Manager
            </Link>
          </button>
        </div>
      </div>
    </>
  );
};

export default BasicSidebar;
