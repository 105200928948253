import React from "react";
import "../MobRecent/Mobrecent.css";

const MobRecent = () => {
  return (
    <>
      <section className="mob-recent-view">
        <div className="container">
          <div className="row mt-4">
            <div className="col-md-12 mt-3">
              <div className="sub-recent-mob-view">
                <div className="row">
                  <div className="col-sm-12 col-12 mt-2">
                    <div className="">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <div className="updates">
                            <p className="new text-center">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 mt-2">
                          <div className="view text-end">
                            <button type="button" className="btn-view ">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 ">
                          <ul>
                            <li className="notice">
                              Notice under Section 142(1)
                            </li>
                            <li className="shubham"> Shubham Kumar</li>
                            <li className="month">2 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto">
                          <div className="view-all text-end ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <ul>
                            <li className="notice">Notice under Section 148</li>
                            <li className="shubham">Paresh More</li>
                            <li className="month">1 Months</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto">
                          <div className="view-all text-end ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bd-right mt-3 mb-3"></div>
                  </div>
                  <div className="col-sm-12 col-12">
                    <div className="recent-background">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <div className="updates">
                            <p className="new text-center">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 mt-2 ">
                          <div className="view text-end">
                            <button type="button" className="btn-view">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <ul>
                            <li className="notice">Shubham Yadav</li>
                            <li className="shubham">
                              {" "}
                              Status -{" "}
                              <span style={{ color: "#01C822" }}>Accepted</span>
                            </li>
                            <li className="month">2 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto ">
                          <div className="view-all text-end ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <ul>
                            <li className="notice">Arjun Mane</li>
                            <li className="shubham">
                              Status -
                              <span style={{ color: "#01C822" }}>
                                {" "}
                                Accepted
                              </span>
                            </li>
                            <li className="month">1 Hours</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto ">
                          <div className="view-all text-end ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bd-right mt-3 mb-3"></div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-6 col-md-6 mt-2 mb-5">
                    <div className="recent-background mb-5">
                      <div className="row">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8 col-sm-8 col-8">
                          <div className="updates">
                            <p className="new text-center">
                              New Notice Generated
                            </p>
                          </div>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 sol-sm-4 col-4 mt-2">
                          <div className="view text-end">
                            <button type="button" className="btn-view">
                              view all
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-2">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <ul>
                            <li className="notice">Rohit Yadav</li>
                            <li className="shubham"> Consolidated invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto">
                          <div className="view-all  text-end">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-3">
                        <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-8 col-8">
                          <ul>
                            <li className="notice">Arjun Mane</li>
                            <li className="shubham">Retainer invoice</li>
                          </ul>
                        </div>
                        <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 sol-sm-4 col-4 my-auto ">
                          <div className="view-all text-end ">
                            <button type="button" className="btn-view-all">
                              view
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default MobRecent;
