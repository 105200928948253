import React from "react";

import Table from "../country/Table";
import Sidebar from "../../layout/Sidebar";
const Country = () => {
  return (
    <>
      <section className="main-section main-sec-bg">
        <div className="container-fluid">
          <div className="row">
            <Sidebar />
            <div className=" col-xl-9  col-lg-8  col-md-12">
              <Table />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Country;
