import React from "react";
import "../../Master/Tabels/Tabels.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import plus from "../../assets/icons/PLUS.png";
import colunms from "../../assets/icons/LINES.png";
import pen from "../../assets/icons/pen.png";
import basket from "../../assets/icons/basket.png";
import search1 from "../../assets/icons/search.png";
import top from "../../assets/icons/top.png";
import blackeye from "../../assets/icons/blackeye.png";
import whiteimg from "../../assets/icons/eye.png";
import { Context } from "../../../../utils/context";
import { useContext } from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { library } from "@fortawesome/fontawesome-svg-core";
import { fas } from "@fortawesome/free-solid-svg-icons";

import { postData } from "../../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
library.add(fas);

const DataView = () => {
  const { getData, editStatusData, deleteData, ErrorNotify } =
    useContext(Context);

  const navigate = useNavigate();
  const handleBackClick = () => {
    // history.goBack(); // This navigates back to the previous page
    navigate(-1);
  };

  const { id } = useParams();

  const [data, setData] = useState([]);

  const getDataProperty = async () => {
    const response = await getData(
      `/createbill/propertyinfo/getallpropertydetailsbyid/${id}`
    );

    setData(response.data);

  };

  useEffect(() => {
    getDataProperty();
  }, []);

  return (
    <>
      <div className="col-xxl-12 col-xl-12">
        <section className="Tabels tab-radio mt-4">
          <div className="container">
            <div className="row">
              <div className="d-flex">
                <div className="add me-3">
                  <Link to="">
                    <button
                      type="button"
                      className="btn btn-add pe-3"
                      onClick={handleBackClick}
                    >
                      {" "}
                      <FontAwesomeIcon
                        icon="fa-solid fa-backward"
                        className="plus me-2 ms-0"
                      />
                      Back{" "}
                    </button>
                  </Link>
                </div>

                <div className="view-details ">
                  {/* <Link to="">
                    {" "}
                    <button className="btn btn-view">
                      <img src={whiteimg} className="eye-img me-2" alt="" />
                      View Details
                    </button>
                  </Link> */}
                </div>
              </div>
            </div>

            {/* <div className="border-line mt-3"></div> */}

            <Tabs className="mt-3">
              <TabList>
                <Tab>Property Info Tab</Tab>
                <Tab>Construction Details Tab</Tab>
                <Tab>Flat Details Tab</Tab>
                <Tab>Room Details Tab</Tab>
              </TabList>

              <TabPanel>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Property Info
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property No.</label>
                    <input
                      type="text"
                      value={data?.property_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Sequence No.</label>
                    <input
                      type="text"
                      value={data?.sequence_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Old Prop. No.</label>
                    <input
                      type="text"
                      value={data?.old_property_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Ref Pro No.</label>
                    <input
                      type="text"
                      value={data?.ref_property_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Ward</label>
                    <input
                      type="text"
                      value={data?.ward_id}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Zone</label>
                    <input
                      type="text"
                      value={data?.zone_id}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Route</label>
                    <input
                      type="text"
                      value={data?.route_id}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Auth. Status</label>
                    <input
                      type="text"
                      value={data?.auth_status}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property Status</label>
                    <input
                      type="text"
                      value={data?.property_status}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Authorised ?</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.authorised}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Legal</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.legal}
                      // className="form-control"
                      id=""
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Property Owner Details
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Initial</label>
                    <input
                      type="text"
                      value={data?.initial}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Last Name </label>
                    <input
                      type="text"
                      value={data?.lastname}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>First Name</label>
                    <input
                      type="text"
                      value={data?.first_name}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Middle Name</label>
                    <input
                      type="text"
                      value={data?.mid_name}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Org. Name</label>
                    <input
                      type="text"
                      value={data?.org_name}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Contact No.</label>
                    <input
                      type="text"
                      value={data?.contact_number}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Pan No.</label>
                    <input
                      type="text"
                      value={data?.pan_number}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Email Id.</label>
                    <input
                      type="text"
                      value={data?.email}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Aadhar Card No.</label>
                    <input
                      type="text"
                      value={data?.aadhar}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Property Details
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property Type</label>
                    <input
                      type="text"
                      value={data?.property_type}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property Sub-Type</label>
                    <input
                      type="text"
                      value={data?.property_sub_type}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Usage Type</label>
                    <input
                      type="text"
                      value={data?.usage_type}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Subtype</label>
                    <input
                      type="text"
                      value={data?.usage_subtype}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>

                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Property Address
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Plot No.</label>
                    <input
                      type="text"
                      value={data?.plot_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>House No.</label>
                    <input
                      type="text"
                      value={data?.house_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Street Name</label>
                    <input
                      type="text"
                      value={data?.street_name}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Building Name</label>
                    <input
                      type="text"
                      value={data?.building_name}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-9 col-md-9 col-12 mt-2">
                    <label>Address</label>
                    <input
                      type="text"
                      value={data?.address}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Location</label>
                    <input
                      type="text"
                      value={data?.location}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Country</label>
                    <input
                      type="text"
                      value={data?.countryname}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>State</label>
                    <input
                      type="text"
                      value={data?.statename}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Districts</label>
                    <input
                      type="text"
                      value={data?.districtname}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Taluka</label>
                    <input
                      type="text"
                      value={data?.talukaname}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>City</label>
                    <input
                      type="text"
                      value={data?.cityname}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>

                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Assessment Date Details
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Current Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.current_asses_date
                          ? new Date(data?.current_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Order Date</label>
                    <input
                      type="date"
                      value={
                        data?.order_date
                          ? new Date(data?.order_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>First Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.first_asses_date
                          ? new Date(data?.first_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Primary Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.primary_asses_date
                          ? new Date(data?.primary_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Last Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.last_asses_date
                          ? new Date(data?.last_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Construction End Date</label>
                    <input
                      type="date"
                      value={
                        data?.construction_end_date
                          ? new Date(data?.construction_end_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Revised Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.revised_asses_date
                          ? new Date(data?.revised_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Updated Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.updated_asses_date
                          ? new Date(data?.updated_asses_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>

                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Building Structure
                  </label>
                  <div className="col-lg-4 col-md-4 col-12 mt-2">
                    <label>Building Structure</label>
                    <input
                      type="text"
                      value={data?.billingstructure}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 mt-2">
                    <label>Floors</label>
                    <input
                      type="text"
                      value={data?.floors}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-4 col-md-4 col-12 mt-2">
                    <label>Total Floors</label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-12 col-md-12 col-12 mt-2">
                    <label>Remarks</label>
                    <input
                      type="text"
                      value={data?.remarks}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Construction Information
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Start Date</label>
                    <input
                      type="date"
                      value={
                        data?.start_date
                          ? new Date(data?.start_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>End Date</label>
                    <input
                      type="date"
                      value={
                        data?.end_date
                          ? new Date(data?.end_date).toISOString().split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>License no. </label>
                    <input
                      type="text"
                      value={data?.licence_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property Age.</label>
                    <input
                      type="text"
                      value={data?.property_age}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>

                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    License Certificate Information
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Certificate No.</label>
                    <input
                      type="text"
                      value={data?.creatificate_no}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Cert. Completion date.</label>
                    <input
                      type="date"
                      value={
                        data?.creatificate_completion_date
                          ? new Date(data?.creatificate_completion_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Cert. Receive Date</label>
                    <input
                      type="date"
                      value={
                        data?.creatificate_receive_date
                          ? new Date(data?.creatificate_receive_date)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id="creatificate_receive_date"
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Agency Name</label>
                    <input
                      type="text"
                      value={data?.agency_name_id}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>License Type</label>
                    <input
                      type="text"
                      value={data?.licence_type_id}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Flat Details
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Flat No. / ID No.</label>
                    <input
                      type="text"
                      value={data?.flat_no_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Location</label>
                    <input
                      type="text"
                      value={data?.location_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Occupancy Status</label>
                    <input
                      type="text"
                      value={data?.occupation_id_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Current Ass. Date</label>
                    <input
                      type="date"
                      value={
                        data?.current_ass_date_3
                          ? new Date(data?.current_ass_date_3)
                              .toISOString()
                              .split("T")[0]
                          : ""
                      }
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Usage Type</label>
                    <input
                      type="text"
                      value={data?.usagetype_id_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Subtype</label>
                    <input
                      type="text"
                      value={data?.usagesubtype_id_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Construction Class</label>
                    <input
                      type="text"
                      value={data?.construction_class_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Provision To Enter Tenant</label>
                    <input
                      type="text"
                      value={data?.provision_tenant_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <label className="mt-4 text-center form-label ">
                    Owner Name
                  </label>
                  {data?.fields?.map((field) => (
                    <>
                      <div className="col-lg-3 col-md-3 col-12 mt-2">
                        <label>Initial</label>
                        <input
                          type="text"
                          disabled
                          value={field?.initial}
                          className="form-control"
                          id=""
                        />
                      </div>

                      <div className="col-lg-3 col-md-3 col-12 mt-2">
                        <label>Last Name </label>
                        <input
                          type="text"
                          disabled
                          value={field?.last_name}
                          className="form-control"
                          id=""
                        />
                      </div>

                      <div className="col-lg-3 col-md-3 col-12 mt-2">
                        <label>First Name</label>
                        <input
                          type="text"
                          disabled
                          value={field?.first_name}
                          className="form-control"
                          id=""
                        />
                      </div>

                      <div className="col-lg-3 col-md-3 col-12 mt-2">
                        <label>Middle Name</label>
                        <input
                          type="text"
                          disabled
                          value={field?.middle_name}
                          className="form-control"
                          id=""
                        />
                      </div>
                    </>
                  ))}

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Standard Rate</label>
                    <input
                      type="text"
                      value={data?.standard_rate_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Built up Area</label>
                    <input
                      type="text"
                      value={data?.built_up_area_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Carpet Area</label>
                    <input
                      type="text"
                      value={data?.carpet_area_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Annual Rent</label>
                    <input
                      type="text"
                      value={data?.annual_rent_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Construction Cost</label>
                    <input
                      type="text"
                      value={data?.construction_cost_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Exempt. Area</label>
                    <input
                      type="text"
                      checked={data?.exempt_area_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Assessible Area</label>
                    <input
                      type="text"
                      value={data?.assessible_area_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Manual RV</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.manual_rv_3}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Auto RV</label> <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.auto_rv_3}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>ALV</label>
                    <input
                      type="text"
                      value={data?.alv_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>RV</label>
                    <input
                      type="text"
                      value={data?.rv_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Property Tax</label>
                    <input
                      type="text"
                      value={data?.property_tax_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Residential</label>
                    <input
                      type="text"
                      value={data?.residential_tax_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Non Residential</label>
                    <input
                      type="text"
                      value={data?.non_residential_tax_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Tree Tax</label>
                    <input
                      type="text"
                      value={data?.tree_tax_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Fire Tax</label>
                    <input
                      type="text"
                      value={data?.fire_tax_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mt-2">
                    <label>Employment Tax On Non Residential Property</label>
                    <input
                      type="text"
                      value={data?.e_t_o_n_r_p_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-6 col-md-6 col-12 mt-2">
                    <label>Remarks</label>
                    <input
                      type="text"
                      value={data?.remarks_3}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div className="row">
                  <label className="mt-4 text-center form-label ">
                    Enter Room Details
                  </label>
                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Flat No. / ID No. </label>
                    <input
                      type="text"
                      value={data?.flat_no_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Room Type</label>
                    <input
                      type="text"
                      value={data?.room_type_id_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Room Width</label>
                    <input
                      type="text"
                      value={data?.room_width_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Room Length </label>
                    <input
                      type="text"
                      value={data?.room_length_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Usage Type</label>
                    <input
                      type="text"
                      value={data?.usage_type_id_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Subtype</label>
                    <input
                      type="text"
                      value={data?.usage_sub_type_id_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Construction Class</label>
                    <input
                      type="text"
                      value={data?.construction_class_id_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Occupancy Status</label>
                    <input
                      type="text"
                      value={data?.occupancy_status_id_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Manual Area</label>
                    <input
                      type="text"
                      value={data?.manual_area_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Exempted</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.exempted_4}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Built up Area</label>
                    <input
                      type="text"
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Carpet Area</label>
                    <input
                      type="text"
                      value={data?.carpet_area_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Exempt. Area</label>
                    <input
                      type="text"
                      value={data?.exempt_area_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Assessible Area</label>
                    <input
                      type="text"
                      value={data?.assessable_area_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Annual rate</label>
                    <input
                      type="text"
                      value={data?.annual_rate_4}
                      disabled
                      className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Manual RV</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.manual_rv_4}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>Auto RV</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.alv_4}
                      // className="form-control"
                      id=""
                    />
                  </div>

                  <div className="col-lg-3 col-md-3 col-12 mt-2">
                    <label>RV</label>
                    <br />
                    <input
                      type="checkbox"
                      disabled
                      checked={data?.rv_4}
                      // className="form-control"
                      id=""
                    />
                  </div>
                </div>
              </TabPanel>
            </Tabs>

            <div className="border-line mt-3 mb-3"></div>
          </div>
        </section>
      </div>
    </>
  );
};

export default DataView;
