import React, { useContext, useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import sa from "../../assets/icons/sa.png";
import pencil from "../../assets/icons/pencil.png";
import backarrow from "../../assets/icons/back.png";
import Sidebar from "../../Master/layout/Sidebar";
import rigth from "../../assets/icons/rigth.png";
import circle from "../../assets/icons/circle.png";
import save from "../../assets/icons/save.png";
import cancel from "../../assets/icons/cross.png";
import { Context } from "../../../../utils/context";

const Edit = () => {
  // const { postCustomerMaster,getsingledatabyid } = useContext(Context); // Assuming you have a getCustomerById function
  // const {id} = useParams();
  // getsingledatabyid(id)
  const { getEditData, editData } = useContext(Context);
  const navigate = useNavigate();

  const { id } = useParams();
  const [data, setData] = useState();

  const [formData, setFormData] = useState({
    name: "",
    propertytypeid: "",
    area: "",
    address: "",
    landmark: "",
    pincodeid: "",
    email: "",
    mobileno: "",
  });

  const [errors, setErrors] = useState({});
  const [showModal, setShowModal] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);

  const validateForm = () => {
    let errors = {};

    const regex = /^[A-Za-z]+$/;

    if (!formData.name.trim()) {
      errors.name = "Customer Name is required";
    } else if (!regex.test(formData.name)) {
      errors.name = "Enter Valid Customer Master Name";
    }

    return errors;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const validationErrors = validateForm();

    // if (Object.keys(validationErrors).length > 0) {
    //   setErrors(validationErrors);
    // } else {
    try {
      const response = await editData(
        "/customermaster/customermaster/customermaster/editcustomermaster",
        formData
      );

      if (response.success) {
        setShowModal(true);
        setTimeout(() => {
          setShowModal(false);
          navigate("/customer-master");
        }, 1000);
      } else {
        setShowErrorModal(true);
        setTimeout(() => {
          setShowErrorModal(false);
          navigate(`/customer-master/edit/${id}`);
        }, 1000);
      }
    } catch (error) {
      console.error("Error submitting form:", error);
    }
    //}
  };

  const GetEditData = async () => {
    const response = await getEditData(
      `/customermaster/customermaster/customermaster/getsigleedit/${id}`
    );
    setData(response);
    setFormData(response?.data);
  };

  useEffect(() => {
    GetEditData();
  }, []);

  const errorStyle = {
    color: "red",
    marginLeft: "5px",
  };

  return (
    <>
      <div>
        <section className="main-section main-sec-bg">
          <div className="container-fluid">
            <div className="row">
              <Sidebar />

              <div className="col-md-9">
                <div className="container">
                  <div className="row mt-5 ">
                    <div className="col-xxl-12 col-xl-12 col-md-10 ">
                      <Link to="/customer-list">
                        <button type="button" className="btn btn-back">
                          <img src={rigth} className="rigth" alt="" /> Back
                        </button>
                      </Link>
                      <hr />
                      <div className="View">
                        <form
                          onSubmit={handleSubmit}
                          role="form"
                          className="form-background"
                        >
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Edit Owner Name
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.name} // Bind the value to the formData property
                                onChange={handleChange}
                                name="name"
                              />
                              {errors.name && (
                                <span style={errorStyle}>{errors.name}</span>
                              )}
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Property Type
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.propertytypeid}
                                onChange={handleChange}
                                name="propertype"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Area of Property (Sq.Ft.)
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.area} // Bind the value to the formData property
                                onChange={handleChange}
                                name="area"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Landmark
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.landmark} // Bind the value to the formData property
                                onChange={handleChange}
                                name="landmark"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Property Detailed Address
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="text"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.address} // Bind the value to the formData property
                                onChange={handleChange}
                                name="address"
                              />
                            </div>
                          </div>

                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Pin Code
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.pincodeid} // Bind the value to the formData property
                                onChange={handleChange}
                                name="pincode"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Email ID
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="email"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.email} // Bind the value to the formData property
                                onChange={handleChange}
                                name="email"
                              />
                            </div>
                          </div>
                          <div className="row mb-3 justify-content-center">
                            <label
                              htmlFor="colFormLabelSm"
                              className="col-sm-3 col-form-label col-form-label-sm"
                            >
                              Mobile Number
                            </label>
                            <div className="col-sm-6">
                              <input
                                type="number"
                                className="form-control form-control-sm"
                                id="colFormLabelSm"
                                placeholder=""
                                value={formData.mobileno} // Bind the value to the formData property
                                onChange={handleChange}
                                name="mobileno"
                              />
                            </div>
                          </div>
                          <div className="row justify-content-center">
                            <div className="col-md-6 text-end">
                              <Link to="/customer-list">
                                <button
                                  type="button"
                                  className="btn btn-cancel me-2"
                                >
                                  {" "}
                                  <img
                                    src={cancel}
                                    className="cancel-img"
                                    alt=""
                                  />{" "}
                                  Cancel
                                </button>
                              </Link>
                            </div>
                            <div className="col-md-6 justify-contend-end">
                              <div className="sa">
                                <button type="submit" className="btn btn-save">
                                  {" "}
                                  <img
                                    src={sa}
                                    className="save-img me-2"
                                    alt=""
                                  />
                                  Save
                                </button>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showModal ? "show" : ""}`}
          style={{ display: showModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Billing Structure Updated Successfully
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* <!-- Modal --> */}
      <div className="save-modal">
        <div
          className={`modal fade ${showErrorModal ? "show" : ""}`}
          style={{ display: showErrorModal ? "block" : "none" }}
          id="exampleModal1"
          tabindex="-1"
          aria-labelledby="exampleModalLabel"
          aria-hidden="true"
        >
          <div className="modal-dialog  modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-body">
                <div className="circle justify-content-end">
                  <img src={circle} className="circle-img mb-2" alt="" />
                </div>
                <h1 className="add-success text-center">
                  Billing Structure Already Exits
                </h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Edit;
